@charset "UTF-8";
@import "./partial/variables";

.addButton {
  box-sizing: border-box;
  display: inline-block;
  padding: 16px;
  font-size: 16px;
  font-weight: bold;
  line-height: 100%;
  color: $secondly_color;
  letter-spacing: 1.25px;
  background: $main_bg_color;
  border: 1px solid $secondly_color;
  border-radius: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}