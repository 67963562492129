@charset "UTF-8";
@import "../../../../asset/css/partial/variables";

.borderBox{
  box-sizing: border-box;
  width: 652px;
  padding: 24px 40px 40px;
  margin: 40px auto 0;
  color: $main_text_color;
  text-align: center;
  background: $main_bg_color;
  border: 1px solid $input_border_color;

  &__title {
    font-size: 24px;
    font-weight: bold;
    line-height: 160%;
    color: $secondly_color;
    text-align: center;
  }

  &__text{
    margin-top: 8px;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.15px;
  }
}
