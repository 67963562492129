@charset "UTF-8";
@import "../../../../asset/css/partial/variables";

.header {
  border-bottom: 1px solid #D8D8D8;

  &__inner{
    display :flex;
    align-items: center;
    justify-content: space-between;
    max-width: 960px;
    padding: 16px 8px;
    margin: auto;
  }

  &__logo{
    display :flex;

    img {
      margin-right: 16px;
    }

    p {
      font-size: 10px;
      line-height: 12px;
      color: $main_text_color;
    }
  }

  &__functions {
    display :flex;

    .logout {
      font-size: 12px;
      font-weight: normal;
      line-height: 14px;
      color: $secondly_color;
      text-decoration: underline;
    }

    .newSchool {
      display: block;
      width: 179px;
      height: 31px;
      margin-left: 24px;
      font-size: 12px;
      font-weight: bold;
      line-height: 31px;
      color: #fff;
      text-align: center;
      letter-spacing: 0.25px;
      background: $secondly_color;
      border-radius: 30px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      transition: background-color 0.3s ease-in-out;
    }
  }
}
