@charset "UTF-8";
@import '../../../../asset/css/partial/variables';

.inputRow {
  display: flex;
}

.inputColumn {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 100%;
  color: #000000;
}

.inputTextWrap {
  flex: 0 0 127px;
  width: 127px;
  margin:  0 8px;
}

.inputColumnSecondLabel {
  width: 208px;
  flex: 0 0 208px;
  text-align: right;
}

.inputColumnTextArea {
  display: flex;
  align-items: center;
  width: 100%;

  &__title {
    flex: 0 0 72px;
    widows: 72px;
  }

  &__input {
    flex: 1;
  }
}