@charset "UTF-8";
@import "./partial/variables";

.inquiryContainer{
  box-sizing: border-box;
  max-width: 960px;
  margin:auto;
  color:$main_text_color
}

.sort {
  display: flex;
  align-items: center;
  margin-top: 16px;
  
  p {
    font-size: 14px;
    line-height: 140%;
    color: #000;
    letter-spacing: 0.1px;
  }
    
  &__selectBoxWrap{
    box-sizing: border-box;
    padding: 10px 28px 10px 20px;
    margin-left: 16px;
    font-size: 12px; 
    font-weight: normal;
    line-height: 100%;
    color: $main_text_color;
    letter-spacing: 0.25px;
    background: $main_bg_color;
    background-image: url("../img/icon_arrow.svg");
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 13px 13px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    &--default {
      color: #DCDCDC;
    }
  }
}

//問い合わせ詳細
.inquiryDetail {
  h2 {
    margin-top: 16px;
  }

  &__head{
    display:flex;
    align-items: flex-start;
    margin-top: 16px;
  }

  &__mainImg {
    width: 140px;
    height: 86px;
  }

  &__textWrap {
    flex-grow: 1;
    margin-left: 8px;
  }

  &__title {
    font-size: 16px;
    font-weight: bold;
    line-height: 140%;
    color: $main_text_color;
    letter-spacing: 0.15px;
  }

  &__place {
    margin-top: 8px;
    font-size: 12px;
    font-weight: normal;
    line-height: 140%;
    color: $main_text_color;
    letter-spacing: 0.4px;
  }

  table {
    width: 100%;
    font-size: 14px;
    table-layout: fixed;
    border-collapse: collapse;
  }

  tr {
    border-bottom: 1px solid $input_border_color;
    &:first-of-type {
      border-top: 1px solid $input_border_color;
    }
  }

  th {
    width: 148px;
    height: auto;
    padding: 16px;
    font-weight: bold;
    line-height: 23.8px;
    text-align: left;
    vertical-align: top;
    background: $sub_bg_color;
  }

  td {
    padding: 16px;
    font-size: 14px;
    line-height: 170%;
    color: $main_text_color;

    a {
      color: $main_text_color;
    }
  }

  &__replay{
    box-sizing: border-box;
    width: 652px;
    padding-bottom:32px;
    margin: 40px auto 0;
    text-align: center;
    background: $main_bg_color;
    border: 1px solid $main_border_color;
    
    &Title {
      margin-top: 24px;
      font-size: 24px;
      font-weight: bold;
      line-height: 160%;
      color: $secondly_color;
      text-align: center;
    }
    &Content{
      width: 458px;
      height: 96px;
      padding: 20px 0 16px;
      margin: auto;
      margin-top: 16px;
      background: #E5F5F0;
    }

    &Email{
      font-size: 18px;
      font-weight: bold;
      line-height: 160%;
      color: #17A076;
      text-decoration-line: underline;
      letter-spacing: 0.15px;
    }

    &Name{
      margin-top: 8px;
      font-size: 16px;
      font-weight: bold;
      line-height: 140%;
      color: $main_text_color;
      letter-spacing: 0.15px;
    }

    p{
      margin: 16px 0 0;
      font-size: 16px;
      line-height: 140%;
      color: $main_text_color;
      text-align: center;
      letter-spacing: 0.15px;
    }
  }

  h3 {
    margin: 32px 0 8px;
    font-size: 14px;
    font-weight: bold;
    line-height: 100%;
    color: $main_text_color;
  }

  &__info {
    font-size: 14px;
    line-height: 170%;
    letter-spacing: 0.25px;
  }

  &__boxWithBorder{
    box-sizing: border-box;
    padding:16px;
    line-height: 140%;
    letter-spacing: 0.15px;
    background: $main_bg_color;
    border: 1px solid $main_border_color;
    border-radius: 3px;
    box-shadow: inset -1px 1px 2px rgba(0, 0, 0, 0.25);
  }

  &__flowImgWrap {
    background: $sub_bg_color;
    padding: 24px 42px;
    margin: 24px 62px 0;
  }

  &__flowImg {
    width: 100%;
  }
}