@charset "UTF-8";
@import "../../../../asset/css/partial/variables";

.schoolHeader {
  margin: 42px 0 16px;
  color: $main_text_color;

  &__inner {
    display: flex;
  }
  &__name {
    flex: 0 0 697px;
    width: 679px;
    font-size: 18px;
    font-weight: bold;
    line-height: 157%;
  }
  &__place {
    margin-top: 16px;
    font-size: 14px;
    line-height: 170%;
    letter-spacing: 0.25px;
  }
  &__status {
    position: relative;
    text-align: right;
  }
  &__update {
    font-size: 12px;
    line-height: 140%;
    text-align: right;
    letter-spacing: 0.4px;
  }
  &__table {
    border-collapse: collapse;
    border: 1px solid $main_border_color;
    th{
      height: 30px;
      font-size: 10px;
      font-weight: normal;
      text-align: center;
      background: $sub_bg_color;
    }
    td {
      width: 96px;
      height: 33px;
      text-align: center;
    }
  }
  &__note {
    font-size: 14px;
    line-height: 170%;
    color: $sub_text_color;
    letter-spacing: 0.25px;
  }
  &__notPublish {
    position: absolute;
    top: 30px;
    right: 104px;
    height: 16px;
    padding: 1px 8px 1px 20px;
    font-size: 10px;
    line-height: 140%;
    color: #fff;
    letter-spacing: 0.015em;
    background: #ff6363;
    border-radius: 4px;
    animation: bound .5s ease infinite alternate;

    svg {
      position: absolute;
      top: 4px;
      left: 8px
    }

    &::after {
      position: absolute;
      top: 4px;
      right: -5px;
      width: 0;
      height: 0;
      content: '';
      border-style: solid;
      border-color: transparent transparent transparent #ff6363;
      border-width: 4px 0 4px 6.9px;
    }
  }

  &__greenBtn {
    display: block;
    line-height: 48px;
    width: 234px;
    height: 48px;
    margin: 16px auto auto;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background: $secondly_color;
    border-radius: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease-in-out;
  }

  &__link {
    font-size: 12px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.4px;
    text-decoration-line: underline;
    color: $secondly_color;
    text-align: center;
    display: block;
    margin-top: 8px;
  }
}

@keyframes bound{
  0% {transform: translateY(0);}
  100% {transform: translateY(-7px);}
}

.state{
  &__selectBoxWrap{
    position: relative;
    display: inline-block;
    margin-top: 6px;

    &::before {
      position: absolute;
      top: 50%;
      left: 8px;
      display: inline-block;
      width: 8px;
      height: 8px;
      content:"";
      background: #888;
      border-radius: 50%;
      transform: translateY(-50%);
    }
    &--active {
      &::before {
        background: $secondly_color;
      }
    }
  }

  &__selectBox{
    box-sizing: border-box;
    padding: 10px 28px 10px 20px;
    font-size: 12px;
    font-weight: bold;
    line-height: 100%;
    color: $main_text_color;
    letter-spacing: 0.25px;
    background: $main_bg_color;
    background-image: url("../img/icon_arrow.svg");
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 13px 13px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
}
