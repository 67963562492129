@charset "UTF-8";
@import "../../../../asset/css/partial/variables";

.tabBar{
  background: #E5F5F0;

  &__list {
    display: flex;
    width: 960px;
    margin: auto;
  }

  &__item {
    padding: 16px 24px;

    a {
      font-size: 14px;
      line-height: 100%;
      color: $secondly_color;
      text-align: center;
      letter-spacing: 1.25px;
    }
    
    &--active {
      background: $main_bg_color;
    }
  }
}