@charset "UTF-8";
@import "../../../../asset/css/partial/variables";

.schoolTabBar {
  &__list {
    display: flex;
    justify-content: flex-start;
    height: 48px;
    background: $sub_bg_color;
    @include mediaForPC() {
      padding: 0 16px;
    }
  }

  &__item{
    width: 130px;
    a {
      position: relative;
      display: block;
      height: 48px;
      font-size: 12px;
      font-weight: bold;
      line-height: 48px;
      color: $main_text_color;
      text-align: center;

      @include mediaForPC() {
        width: 130.4px;
        font-size: 14px;
      }
    }
    &--active {
      color: $secondly_color;
      pointer-events: none;
      cursor: auto;
      background: $main_bg_color;
      border-bottom: 2px solid $secondly_color;

      .schoolDetailTab__count {
        bottom: 5px;
      }
      a {
        color:$secondly_color
      }
    }
    &--deactivate {
      color: #c4c4c4;
      pointer-events: none;
      cursor: auto;
    }
  }

  &__count {
    position: absolute;
    right: 0;
    bottom: 8px;
    left: 0;
    display: inline-block;
    margin: 0 auto;
    font-size: 10px;
    font-weight: normal;
    line-height: 1;

    @include mediaForPC() {
      bottom: 6px;
    }
  }
}
