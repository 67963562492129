@charset "UTF-8";
@import "./partial/variables";

.wrap {
  box-sizing: border-box;
  max-width: 600px;
  padding: 16px;
  margin: auto;

  h1 {
    padding: 16px 0;
    margin: auto;
    font-size: 20px;
  }

  h2 {
    padding: 16px 0;
    margin: auto;
    font-size: 16px;
    border: none;
  }

  div {
    font-size: 12px;
    line-height: 1.5;

    &.foot {
      margin-top: 32px;
    }

    &.right {
      text-align: right;
    }
  }
}
