@charset "UTF-8";
@import "../../../../asset/css/partial/variables";

.wrap {
  padding: 16px;

  &--withLongTitle {
    .list {
      padding: 16px;
    }
    
    .listRow__title{
      flex: 0 0 194px;
      width: 194px;
    }
  }
}

.list {
  width: 928px;
  box-sizing: border-box;
  padding: 16px 32px;
  background: $main_bg_color;
  border: 1px solid $placeholder_text_color;
}

.listRow {
  display: flex;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 140%;
  color: $main_text_color;
  letter-spacing: 0.15px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &__title {
    flex: 0 0 130px;
    width: 130px;
    text-align: right;
  }

  &__text {
    flex: 1;
    margin-left: 16px;
    font-weight: bold;
  }
}
