@charset "UTF-8";
@import "../../../../asset/css/partial/variables";


.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 110;
  width: 100vw;
  overflow-y: scroll;
  line-height: 1;
  color: $main_text_color;
  pointer-events: none;
  background: $main_bg_color;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  @include mediaForPC() {
    width: 800px;
    height: 597px;
    padding: 0;
    margin: auto;
    border-radius: 8px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }

  &.modalShow {
    pointer-events: auto;
    opacity: 1;
  }

  &__header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 47px;
    padding: 16px 16px 8px;
    background: $main_bg_color;
    border-bottom: 1px solid $placeholder_text_color;
    border-radius: 8px 8px 0 0;

    @include mediaForPC() {
      top: auto;
      left: auto;
      width: 800px;
      height: 49px;
      filter: drop-shadow(0 4px 5px rgba(0, 0, 0, 0.14));
    }
  }

  &__headerTitle {
    font-size: 16px;
    font-weight: bold;
    line-height: 140%;
    color: $main_text_color;
    letter-spacing: 0.15px;

    @include mediaForPC() {
      text-align: center;
    }
  }

  &__headerClose {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 16px;
    height: 16px;

    img {
      width: 16px;
      height: 16px;
    }
  }
}

.modalBG {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 109;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.25);
  opacity: 0;
  transition: 0.3s ease-in-out;

  &.modalShow {
    pointer-events: auto;
    opacity: 1;
  }
}
