//color
$primary_color: #0768ab;
$secondly_color: #17a076;
$attention_color: #ff385c;

$main_bg_color: #fff;
$gray_bg_color: #eee;
$sub_bg_color: #f9f9f9;
$main_text_color: #545454;
$sub_text_color: #888;
$black_text_color: #484848;
$link_text_color: #0768ab;
$link_text_color_client: #1760A0;
$placeholder_text_color: #dcdcdc;

$pos_first_color: #e95973;
$pos_second_color: #17a076;
$pos_third_color: #17a076;
$pos_other_color: #0768ab;

$main_border_color: #dcdddd;
$author_border_color: #979797;
$input_border_color: #dcdcdc;

$twitter_color: #1da1f2;
$facebook_color: #1877f2;
$line_color: #00b900;

$qa_bg_color: #f8f8f8;

//z-index

$header_z_index_val: 100;
$topModal_z_index_val: 101;
$drawerMenu_z_index_val: 105;
$searchMenu_z_index_val: 106;

@mixin mediaForPC() {
  @media screen and (min-width: 960px) {
    @content;
  }
}

@mixin mediaForTab() {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin categoryBg($style) {
  &--news {
    @extend .#{$style};

    color: #ff385c;
    background: #fff;
    border: 1px solid #ff385c;
  }

  &--study {
    @extend .#{$style};

    color: #0768ab;
    background: #fff;
    border: 1px solid #0768ab;
  }

  &--homestudy {
    @extend .#{$style};

    color: #0b9afc;
    background: #fff;
    border: 1px solid #0b9afc;
  }

  &--lesson {
    @extend .#{$style};

    color: #17a076;
    background: #fff;
    border: 1px solid #17a076;
  }

  &--product {
    @extend .#{$style};

    color: #edb150;
    background: #fff;
    border: 1px solid #edb150;
  }

  &--art {
    @extend .#{$style};

    color: #a77a32;
    background: #fff;
    border: 1px solid #a77a32;
  }

  &--sports {
    @extend .#{$style};

    color: #23c493;
    background: #fff;
    border: 1px solid #23c493;
  }

  &--exam {
    @extend .#{$style};

    color: #fd002e;
    background: #fff;
    border: 1px solid #fd002e;
  }

  &--school {
    @extend .#{$style};

    color: #ba2b45;
    background: #fff;
    border: 1px solid #ba2b45;
  }

  &--case {
    @extend .#{$style};

    color: #0682d9;
    background: #fff;
    border: 1px solid #0682d9;
  }

  &--overseas {
    @extend .#{$style};

    color: #01355a;
    background: #fff;
    border: 1px solid #01355a;
  }

  &--etc {
    @extend .#{$style};

    color: #888;
    background: #fff;
    border: 1px solid #888;
  }
}

@mixin qaCategoryBg($style) {
  &--education {
    @extend .#{$style};

    color: #0768ab;
  }

  &--habits {
    @extend .#{$style};

    color: #01355a;
  }

  &--preschool {
    @extend .#{$style};

    color: #fd002e;
  }

  &--primary {
    @extend .#{$style};

    color: #ba2b45;
  }

  &--lesson {
    @extend .#{$style};

    color: #23c493;
  }

  &--class {
    @extend .#{$style};

    color: #17a076;
  }

  &--study {
    @extend .#{$style};

    color: #0b9afc;
  }

  &--goout {
    @extend .#{$style};

    color: #0768ab;
  }

  &--play {
    @extend .#{$style};

    color: #a77a32;
  }

  &--worry {
    @extend .#{$style};

    color: #888;
  }

  &--chat {
    @extend .#{$style};

    color: #ff385c;
  }

  &--success {
    @extend .#{$style};

    color: #0682d9;
  }
}
