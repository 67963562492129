@charset "UTF-8";
@import "../../../../asset/css/partial/variables";

.wrap {
  width: 960px;
  padding: 0 16px;
  margin: 40px auto 24px;
}

.title {
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 18px;
  line-height: 157%;
  color: #545454;

}

.description {
  height: 24px;
  margin-bottom: 8px;
  font-weight: normal;
  font-size: 14px;
  line-height: 170%;
  letter-spacing: 0.25px;
  color: #545454;
}

.step {
  height: 56px;
  background: #F9F9F9;
  display: flex;
  align-items: center;
  justify-content: center;

  &__text {
    position: relative;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.1px;
    color: #C4C4C4;

    &.active{
      color: $secondly_color;

      .step__number {
        background:$secondly_color;
      }
    }
  }

  &__number {
      color:  #fff;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      width: 24px;
      height: 24px;
      background: #C4C4C4;
      border-radius: 50%;
  }

  &__border {
    margin: 0 16px;
    width: 120px;
    height: 1px;
    background: #C4C4C4;

    &.active {
      background:$secondly_color;
    }
  }
}