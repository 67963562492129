@charset "UTF-8";
@import "../../../../../asset/css/partial/variables";

.wrap {
  padding-top: 89px;
}

.textWrap {
  box-sizing: border-box;
  width: 652px;
  padding: 40px 96px;
  margin: auto auto 24px;
  border: 1px solid #DCDCDC;
}

.textTitle {
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: bold;
  line-height: 160%;
  color: #545454;
  text-align: center;
}

.text {
  width: 460px;
  font-size: 14px;
  line-height: 1.7;
  color: #545454;
  text-align: left;
  letter-spacing: 0.25px;
}

.buttonWrap {
  margin: auto;
  text-align: center;
}

.confirmButton {
  display: block;
  width: 304px;
  height: 64px;
  margin: auto auto 24px;
  font-size: 16px;
  font-weight: bold;
  line-height: 100%;
  color: #FFF;
  text-align: center;
  letter-spacing: 1.25px;
  background: #17A076;
  border-radius: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.cancelButton {
  box-sizing: border-box;
  width: 304px;
  height: 64px;
  font-size: 16px;
  font-weight: bold;
  line-height: 100%;
  color: #17A076;
  text-align: center;
  letter-spacing: 1.25px;
  background: #FFF;
  border: 1px solid #17A076;
  border-radius: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
