@charset "UTF-8";
@import "../../../../asset/css/partial/variables";

.list {
  width: 928px;
  margin: 40px auto 0;
}

.listTitle {
  display: flex;
  width: 100%;
  height: 49px;
  background: $sub_bg_color;

  > div {
    display: flex;
    align-items: flex-start;
    padding: 16px;
    font-weight: bold;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: 0.4px;
    color: $main_text_color;

    > span {
      font-size: 10px;
    }
  }
}

.listTitleUser {
  flex: 375px;
}

.listTitleLastLogin {
  flex: 107px;
}

.listTitleRole {
  flex: 106px;
}

.listTitleAddDay {
  flex: 101px;
}

.listTitleMail {
  flex: 129px;
}

.listTitleAction {
  flex: 110px;
}