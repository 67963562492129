@charset "UTF-8";
@import '../../../../asset/css/partial/variables';

.wrap {
  margin-top: 16px;
}

.innerWrap {
  display: flex;
  align-items: center;
}

.selectWrap {
  position: relative;
  display: inline-block;
  width: 90px;
  flex: 0 0 90px;
  margin: 0 8px 0 16px;
  transition: opacity 0.25s ease-in-out;


  &.disable {
    pointer-events: none;
    cursor: auto;
    opacity: 0.3;
  }
}

.firstSelectWrap {
  @extend .selectWrap;
  width: 118px;
  flex: 0 0 118px;
  margin: 0 8px 0 0;
}

.select {
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  padding: 8px 48px 8px 16px;
  font-size: 16px;
  line-height: 100%;
  background: #fff;
  border: 1px solid $input_border_color;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  &.noValue {
    color: $placeholder_text_color;
  }

  &:focus {
    color: $main_text_color;
  }

  &.hide {
    display: none;
  }

  option:disabled {
    color: $placeholder_text_color;
  }
}

.arrow {
  position: absolute;
  top: 0;
  right: 16px;
  bottom: 0;
  width: 16px;
  height: 16px;
  margin: auto;
  pointer-events: none;

  img {
    width: 100%;
    height: auto;
  }
}

.selectText {
  font-size: 16px;
  line-height: 100%;
  color: #545454;
}