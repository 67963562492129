@charset "UTF-8";
@import "../../../../asset/css/partial/variables";

.schoolBlogCard {
  display: block;
  padding: 16px;
  margin: 16px 16px 0;
  background: $main_bg_color;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  &__head{
    display:flex;
    align-items: flex-start;
  }
  
  &__mainImg {
    width: 140px;
    height: 86px;
  }

  &__status{
    position: absolute;
    flex-shrink: 0;
    height: 30px;
    padding: 8px;
    font-size: 14px;
    line-height: 100%;
    color: #fff;
    letter-spacing: 1.25px;
    background: #FFCA67;

    &--hidden {
      color: #000;
      background: $sub_bg_color;
    }
  }

  &__textWrap {
    flex-grow: 1;
    margin-left: 8px;

    &--noImg {
      margin-left: 69px;
    }
  }

  &__title {
    font-size: 16px;
    font-weight: bold;
    line-height: 140%;
    color: $main_text_color;
    letter-spacing: 0.15px;
  }

  &__menuBtn{
    margin-top: 8px;
  }

  &__infoWrap{
    display: flex;
  }
  &__info{
    margin-top: 8px;
    font-size: 12px;
    line-height: 140%;
    color: $main_text_color;
    letter-spacing: 0.4px;

    &--hidden {
      margin-left: 16px;
      color: #C4C4C4;
    }
  }
}
