@charset "UTF-8";
@import "../../../../asset/css/partial/variables";

.schoolPageExplanation {
  padding: 24px 16px 40px;
  color: $main_text_color;;
  
  &__title{
    font-size: 18px;
    font-weight: bold;
    line-height: 157%;
  }

  &__text{
    margin-top: 8px;
    font-size: 14px;
    line-height: 170%;
    letter-spacing: 0.25px;
  }

  a {
    box-sizing: border-box;
    display: inline-block;
    padding: 16px;
    margin-top: 16px;
    font-size: 16px;
    font-weight: bold;
    line-height: 100%;
    color: $secondly_color;
    letter-spacing: 1.25px;
    background: $main_bg_color;
    border: 1px solid $secondly_color;
    border-radius: 24px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
}
