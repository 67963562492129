@charset "UTF-8";
@import "../../../../asset/css/partial/variables";

.even {
  background: $sub_bg_color;
}

td {
  padding: 0 8px;
  font-size: 12px;
}

.name{
  min-width: 298px;
  max-width: 298px;
  font-size: 14px;

  a {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    color: $secondly_color;
    text-decoration: underline;
  }
}

.place{
  min-width: 152px;
  max-width: 152px;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}

.update {
  width: 84px;
}

.state{
  width: 125px;

  &__selectBoxWrap{
    position: relative;

    &::before {
      position: absolute;
      top: 50%;
      left: 8px;
      display: inline-block;
      width: 8px;
      height: 8px;
      content:"";
      background: #888;
      border-radius: 50%;
      transform: translateY(-50%);
    }
    &--active {
      &::before {
        background: $secondly_color;
      }
    }
  }
  
  &__selectBox{
    box-sizing: border-box;
    padding: 10px 28px 10px 20px;
    font-size: 12px; 
    font-weight: bold;
    line-height: 100%;
    color: $main_text_color;
    letter-spacing: 0.25px;
    background: $main_bg_color;
    background-image: url("../img/icon_arrow.svg");
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 13px 13px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
}

.numberOfInquiry{
  width: 71px;
  text-align: right;
}

.cost,.budget{
  width: 96px;
  text-align: right;
}

//チェックボックス
.schoolListCheckBox{
  display:none;
}

.schoolListLabel{
  position: relative;
  display: block;
  padding-left: 24px;
  margin: 0 24px 0 0;
  font-size: 14px;
  color: $main_text_color;

  &:hover {
    cursor: pointer;
  }

  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    display: block;
    width: 16px;
    height: 16px;
    content: '';
    background: $main_bg_color;
    border: 1px solid $input_border_color;
    border-radius: 2px;
    box-shadow: inset -1px -1px 2px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease-in-out;
  }
  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 1px;
    display: block;
    width: 14px;
    height: 14px;
    content: '';
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQuNzc0MjkgMTEuNTVDNC41NTAyOSAxMS41NSA0LjMyNjI5IDExLjQ1MiA0LjE3MjI5IDExLjI5OEwwLjgxMjI4OCA3Ljg0QzAuNDkwMjg4IDcuNTA0IDAuNDkwMjg4IDYuOTcyMDEgMC44MjYyODggNi42NTAwMUMxLjE2MjI5IDYuMzI4MDEgMS42OTQyOSA2LjMyODAxIDIuMDE2MjkgNi42NjRMNC43ODgyOSA5LjUyMDAxTDExLjU5MjMgMi42MTgwMUMxMS45MTQzIDIuMjgyMDEgMTIuNDQ2MyAyLjI4MjAxIDEyLjc4MjMgMi42MDQwMUMxMy4xMTgzIDIuOTI2MDEgMTMuMTE4MyAzLjQ1ODAxIDEyLjc5NjMgMy43OTQwMUw1LjM3NjI5IDExLjMxMkM1LjIyMjI5IDExLjQ2NiA0Ljk5ODI5IDExLjU1IDQuNzc0MjkgMTEuNTVaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K');
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }
}


.schoolListCheckBox:checked + .schoolListLabel {
  &::before {
    box-sizing: border-box;
    background: $secondly_color;
    border: 1px solid $secondly_color;
    box-shadow: inset -1px -1px 2px rgba(0, 0, 0, 0.2);
  }

  &::after {
    opacity: 1;
  }
}

.paymentLink {
  font-size: 13px;
  text-align: center;

  button {
    color: $secondly_color;
    text-decoration: underline;
  }

}