@charset "UTF-8";
@import "./partial/variables";

.wrap {
  width: 960px;
  margin: auto;
}

.title {
  padding: 0 0 16px 16px;
  border-bottom: 1px solid $input_border_color;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.15px;
  color: $main_text_color;
}

.inputWrap {
  margin: 16px auto 135px;
  width: 652px;

  &--update {
    @extend .inputWrap;
    width: 927px;
  }
}

.input {
  padding: 24px;

  &:nth-child(odd) {
    background: $sub_bg_color;
  }
}

.inputTextWrap {
  width: 468px;
}

.buttonWrap {
  margin-top: 40px;
  text-align: center;
}

.snsLabel {
  display: flex;
  align-items: center;
  height: 18px;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: bold;
  line-height: 100%;
  color: $main_text_color;
}

.snsInner {
  width: 402px;
  border: 1px solid $input_border_color;
  padding: 16px;
  box-sizing: border-box;

  input {
    width: 287px;
  }
}

.resultWrap {
  width: 652px;
  margin: 40px auto 96px;
  padding: 40px 24px;
  border: 1px solid $input_border_color;
  box-sizing: border-box;

  &__title {
    height: 38px;
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 24px;
    line-height: 38px;
    text-align: center;
    color: $secondly_color;
  }

  &__main {
    padding: 24px;
    background: $sub_bg_color;
    text-align: center;
  }

  &__mainTitle {
    margin-bottom: 24px;
    font-weight: bold;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.15px;
    color: #FF6363;
  }

  &__mainText {
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.15px;
    color: #545454;

    span {
      font-weight: bold;
    }
  }
}

.resultButtonWrap {
  text-align: center;
}

.resultLink {
  margin-top: 24px;
  text-align: center;
  button {
    color: $secondly_color;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 1.25px;
  }
}

.secondMain {
  padding: 40px 72px;
  background: #FFFFFF;
  border: 1px solid $input_border_color;
  box-sizing: border-box;
  text-align: center;

  &__title {
    font-weight: bold;
    font-size: 24px;
    line-height: 160%;
    text-align: center;
    color: $main_text_color;
  }

  &__img {
    width: 100%;
    margin-top: 24px;
  }
}