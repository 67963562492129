@charset "UTF-8";
@import '../../../../asset/css/partial/variables';

.wrap {
  position: relative;
  width: 310px;
  height: 192px;
  padding: 8px;
  margin: 8px 8px 0 0;
  background: $sub_bg_color;

  &--change {
    margin-bottom: 41px;
  }

  &.smallWrap {
    width: 129px;
    height: 80px;
  }

  &.exBigWrap {
    width: 652px;
    height: 348px;
  }

  &.mediumWrap {
    width: 145px;
    height: 145px;
    background: $main_bg_color;
  }

  &.identificationWrap {
    width: 206px;
    height: 166px;
  }
}

.inner {
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  height: 100%;
  text-align: center;
  border: 1px solid $secondly_color;
}

.mainText {
  margin: 47px auto 16px;
  font-size: 14px;
  line-height: 140%;
  color: #000;
  text-align: center;
  letter-spacing: 0.1px;

  &.exBigMainText {
    margin-top: 120px;
  }
}

.identificationTitle {
  margin: 16px 8px 0;
  font-size: 14px;
  font-weight: bold;
  line-height: 140%;
  color: $main_text_color;
  text-align: left;
  letter-spacing: 0.1px;
}

.identificationText {
  margin: 8px 6px 0 8px;
  font-size: 12px;
  line-height: 140%;
  color: $sub_text_color;
  text-align: left;
  letter-spacing: 0.4px;
}

.buttonWrap {
  button {
    box-sizing: border-box;
    width: 155px;
    height: 33px;
    margin: 0 10px;
    font-size: 12px;
    font-weight: bold;
    line-height: 26%;
    color: $secondly_color;
    text-align: center;
    letter-spacing: 0.25px;
    background: $main_bg_color;
    border: 1px solid $secondly_color;
    border-radius: 28px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  &.smallButtonWrap {
    &.buttonWrap--change{
      bottom: -34px;
      left: 0;
      transform: initial;

      button{
        margin-left: 0;
      }
    }

    button {
      width: 90px;
      height: 30px;
      margin-top: 16px;
    }
  }

  &.mediumButtonWrap {
    &.buttonWrap--change{
      bottom: -34px;
      left: 0;
      transform: initial;

      button{
        margin-top: 8px;
        margin-left: 0;
      }
    }

    button {
      width: 77px;
      height: 30px;
      margin-top: 50px;
    }
  }

  &.identificationButtonWrap{
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);

    &.buttonWrap--change{
      bottom: -49px;
      left: 0;
      transform: initial;
    }

    button {
      margin: 0;
    }
  }
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.buttonWrap--change{
  position: absolute;
  bottom: -41px;
  left: 50%;
  transform: translateX(-50%);
}

.deleteButtonWrap {
  position: absolute;
  right: 0;
  bottom: -28px;

  button {
    font-size: 14px;
    line-height: 140%;
    color: $secondly_color;
    text-align: center;
    text-decoration: underline;
  }
}

.smallDeleteButtonWrap {
  &.deleteButtonWrap {
    bottom: -26px;
    
    button{
      font-size: 12px;
    }
  }
}

.identificationDeleteButtonWrap {
  &.deleteButtonWrap {
    bottom: -38px;
    
    button{
      font-size: 14px;
    }
  }
}

.uploadedText {
  margin-top: 64px;
  font-size: 16px;
  font-weight: bold;
  line-height: 140%;
  color: $main_text_color;
  letter-spacing: 0.15px;
}