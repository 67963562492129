@charset "UTF-8";
@import '../../../../../asset/css/partial/variables';

.label {
  display: flex;
  align-items: center;
  height: 18px;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: bold;
  line-height: 100%;
  color: $main_text_color;

  &.require {
    &::after {
      display: inline-block;
      height: 16px;
      padding: 0 7px;
      margin-left: 8px;
      font-size: 10px;
      line-height: 16px;
      color: #ff6363;
      letter-spacing: 0.01em;
      content: '必須';
      border: 1px solid #ff6363;
      background: #fff;
    }
  }
}

.row {
  min-height: 42px;
  margin-bottom: 4px;
}

.inputLabel {
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 0 12px 24px;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 140%;
  transition: all 0.25s ease-in-out;

  &:hover {
    cursor: pointer;
  }

  &::before {
    position: absolute;
    top: 14px;
    left: 0;
    box-sizing: border-box;
    display: block;
    width: 16px;
    height: 16px;
    margin: auto;
    content: '';
    background: #fff;
    border: 1px solid $input_border_color;
    border-radius: 52px;
    box-shadow: inset -1px -1px 2px rgba(0, 0, 0, 0.2);
    transition: all 0.25s ease-in-out;
  }

  &::after {
    position: absolute;
    top: 18px;
    left: 4px;
    box-sizing: border-box;
    display: block;
    width: 8px;
    height: 8px;
    margin: auto;
    content: '';
    background: $secondly_color;
    border-radius: 8px;
    opacity: 0;
    transition: all 0.25s ease-in-out;
  }
}

.radioInput {
  display: none;

  &:checked + .inputLabel {
    color: $secondly_color;

    &::before {
      border-color: $secondly_color;
    }

    &::after {
      opacity: 1;
    }
  }
}
