@charset "UTF-8";
@import "../../../../asset/css/partial/variables";

.schoolDetailTopNotice {
  margin: 24px 0 6px;
  line-height: 1.57;
  color: $main_text_color;

  &__title {
    font-size: 14px;
    font-weight: bold;
    color: #f00;
  }

  &__text {
    margin-top: 8px;
    white-space: pre-wrap;
  }
}
