@charset "UTF-8";
@import "../../../../asset/css/partial/variables";

.borderBox{
  box-sizing: border-box;
  width: 652px;
  padding: 24px 40px;
  margin: 40px auto 0;
  color: $main_text_color;
  text-align: center;
  background: $main_bg_color;
  border: 1px solid $input_border_color;

  &__title {
    font-size: 24px;
    font-weight: bold;
    line-height: 160%;
    color: $secondly_color;
    text-align: center;
  }

  &__text{
    margin-top: 8px;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.15px;
  }

  .planImgWarp {
  display: flex;
  padding: 24px 35px 34px 0; 
  margin-top: 16px;
  background: $sub_bg_color;
  }

  .planImgInner {
    text-align: left;
  }

  .planImg {
    &.title {
      width: 234px;
      height: 43px;
    }

    &.content {
      width: 322px;
      margin: 24px 0 0 40px;
    }

    &.sp {
      flex: 0 0 128px;
      height: 238px;
    }
  }

  .planImgText {
    font-size: 10px;
    line-height: 140%;
    letter-spacing: 0.015em;
    color: $sub_text_color;
    margin: 8px 0 0 40px;
    width: 342px;
  }

  button {
    height:64px;
    margin: 40px 0 0;
  }
}
