@charset "UTF-8";
@import "./partial/variables";

.myPageContainer{
  box-sizing: border-box;
  max-width: 960px;
  margin:auto;
  color:$main_text_color
}

h2 {
  padding-bottom: 16px;
  margin-top: 64px;
  font-size: 16px;
  font-weight: bold;
  line-height: 140%;
  color: $main_text_color;
  letter-spacing: 0.15px;
  border-bottom: 1px solid $input_border_color;
}

//本人確認
.authContainer {
  box-sizing: border-box;
  color: $main_text_color;
  text-align: center;
}
.confirmation {
  box-sizing: border-box;
  width: 652px;
  padding: 24px 40px;
  margin: 40px auto 0;
  color: $main_text_color;
  background: #FFF;
  border: 1px solid $input_border_color;

  &__conversation {
    font-size: 24px;
    font-weight: bold;
    line-height: 160%;
    color: $secondly_color;
    text-align: center;
  }

  &__text {
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.15px;
  }

  &__textInputWrap{
    width: 431px;
    margin: 24px auto 0;
  }

  &__img {
    margin: 16px auto -16px;

    &--human{
      width: 196px;
      height: 163px;
    }

    &--cats{
      width: 256px;
      height: 122px;
    }

    img {
      width:100%;
      height: 100%;
      object-fit:contain;
    }
  }

  &__ngImg{
    margin: 72px auto 0;
    width: 812px;
    padding-bottom: 46px;

    img {
      width: 100%;
    }
  }
}

//本人・事業運営者確認書類提出
.identificationContainer {
  max-width: 652px;
  margin: auto;
  text-align: center;
}

.identificationDescription{
  padding:16px;
  margin: 16px auto 0;
  line-height: 30px;
  color: $main_text_color;
  text-align: left;
  letter-spacing: 0.5px;
  background: #F9F9F9;

  span{
    font-weight: bold;
  }
}

.identificationTitle{
  font-size: 14px;
  font-weight: bold;
  line-height: 100%;
  color: $main_text_color;
  text-align: left;

  span {
    box-sizing: border-box;
    display: inline-block;
    padding:4px 8px;
    margin-left: 8px;
    font-size: 10px;
    font-weight: normal;
    line-height: 100%;
    color: #FF6363;
    letter-spacing: 0.01em;
    background: #FFF;
    border: 1px solid #FF6363;
  }
}

.identificationCaution{
  box-sizing: border-box;
  width: 652px;
  padding: 24px 16px;
  margin: 24px auto 66px;
  color: $main_text_color;
  background: #FFF;
  border: 1px solid $input_border_color;

  &__title{
    margin: 40px 0 16px;
    font-size: 16px;
    font-weight: bold;
    line-height: 30px;
    color: $main_text_color;
    text-align: center;
    letter-spacing: 0.5px;

    &--green {
      margin: 0 0 24px;
      color: $secondly_color;
    }
  }

  &__list {
    font-size: 16px;
    line-height: 30px;
    color: $main_text_color;
    text-align: left;
    letter-spacing: 0.5px;

    span {
      font-weight: bold;
    }
  }

  &__text {
    margin-top: 24px;
    font-size: 16px;
    line-height: 30px;
    color: $main_text_color;
    text-align: left;
    letter-spacing: 0.5px;

    span {
      font-weight: bold;
    }

    a {
      color: $secondly_color;
      text-decoration: underline;
    }
  }

  table , td, th {
    text-align: left;
    border-collapse: collapse;
  }

  tr {
    border-bottom: 1px solid $input_border_color;

    &:first-child{
      border-bottom: 1px solid #fff;

      th{
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0.1px;
        border-left: 1px solid $input_border_color;
      }

      th:first-child{
        border-left: none;
      }
    }
  }

  th{
    padding:16px;
    font-size: 16px;
    font-weight: bold;
    line-height: 140%;
    color: $main_text_color;
    letter-spacing: 0.15px;
    background: $sub_bg_color;
  }

  td {
    padding:16px;
    font-size: 16px;
    line-height: 140%;
    color: $main_text_color;
    letter-spacing: 0.15px;
    border-left: 1px solid $input_border_color;

    &:nth-child(3){
      max-width: 264px;
    }

    span {
      display: block;
      margin-top: 8px;
      font-size: 14px;
      line-height: 140%;
      color: $sub_text_color;
      letter-spacing: 0.1px;
    }
  }
}

.identificationImageWrap{
  display: flex;
}

.identificationImageUploaderNote {
  margin: 8px 0 62px;
  font-size: 12px;
  line-height: 100%;
  color: $sub_text_color;
  text-align: left;
}


.identificationSecurity{
  margin-top: 16px;
  font-size: 14px;
  line-height: 170%;
  color: $sub_text_color;
  text-align: left;
  letter-spacing: 0.25px;
}

.sendDocumentResultTitle {
  margin: 40px auto;
  font-weight: bold;
  line-height: 160%;
  font-size: 24px;
  text-align: center;
}
