@charset "UTF-8";
@import "../../../../asset/css/partial/variables";

.whiteBtn {
  width: 320px;
  height: 48px;
  margin-top: 16px;
  font-size: 16px;
  font-weight: bold;
  color: $secondly_color;
  background: $main_bg_color;
  border: 1px solid $secondly_color;
  border-radius: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  a {
    display: block;
    line-height: 48px;
    color: $secondly_color;
  }
}
