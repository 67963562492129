@charset "UTF-8";
@import "./partial/variables";

.grayBg {
  width: 652px;
  padding: 26px 24px;
  margin: auto;
  background: $sub_bg_color;
  &:first-of-type{
    margin-top: 40px;
  }
}

.whiteBg {
  width: 652px;
  padding: 26px 24px;
  margin: auto;
  background: $main_bg_color;
}

.inputWrapBorder {
  padding: 16px;
  border: 1px solid #DCDCDC;
}