@charset "UTF-8";
@import "../../../../asset/css/partial/variables";

.loadingBG {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  background: rgba(0,0,0,0.3);
  opacity: 0;
  transition: opacity 0.25s 1s;

  &.active {
    display: block;
    pointer-events: auto;
    opacity: 1;
  }
}

.spinnerWrap {
  position: fixed;
  top:0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  width: 100px;
  height: 100px;
  padding: 10px;
  margin: auto;
  pointer-events: none;
  background: rgba(255,255,255,0.7);
  border-radius: 8px;
  opacity: 0;
  transition: opacity 0.25s 1s;

  &.active {
    display: block;
    opacity: 1;
  }
}
