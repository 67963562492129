@charset "UTF-8";
@import "./partial/variables";

//共通
.authContainer {
  box-sizing: border-box;
  color: $main_text_color;
  text-align: center;
}

h1 {
  margin-top: 64px;
  font-size: 24px;
  font-weight: bold;
  line-height: 160%;
  color: $main_text_color;
}

.btnAnnotation {
  margin: 40px 0 -32px;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.1px;

  a {
    color: $link_text_color_client;
  }
}

.links {
  margin-top: 64px;

  .link {
    a {
      font-size: 16px;
      font-weight: 500;
      line-height: 140%;
      color: $link_text_color_client;
      letter-spacing: 0.15px;
    }

    & + .link {
      margin-top: 24px;
    }
  }
}

//入力画面系固有
.grayBg {
  width: 652px;
  padding: 40px 96px;
  margin: auto;
  margin-top: 40px;
  background: $sub_bg_color;


  &__list {
    margin-top: 24px;
    text-align: left;

    li {
      font-size: 14px;
      line-height: 170%;
      color: #000;
      letter-spacing: 0.25px;
    }
  }
}

//確認画面系固有
.confirmation {
  box-sizing: border-box;
  width: 652px;
  padding: 24px 40px 40px;
  margin: 40px auto 0;
  color: $main_text_color;
  background: #FFF;
  border: 1px solid $input_border_color;

  &--register {
    @extend .confirmation;
    padding-bottom: 24px;
  }

  &__conversation {
    font-size: 24px;
    font-weight: bold;
    line-height: 160%;
    color: $secondly_color;
    text-align: center;
  }

  &__title {
    font-size: 12px;
    font-weight: bold;
    line-height: 140%;
    letter-spacing: 0.4px;
  }

  &__text {
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.15px;
  }

  span {
    color: $secondly_color;
  }

  &__list {
    margin-top: 8px;
    text-align: left;

    li {
      font-size: 12px;
      line-height: 140%;
      color: $main_text_color;
      letter-spacing: 0.4px;

      & + li {
        margin-top: 16px;
      }
    }
  }

  &__emphasisList {
    text-align: left;
    list-style: disc;

    li {
      font-size: 14px;
      font-weight: bold;
      line-height: 170%;
      color: $main_text_color;

      & + li {
        margin-top: 16px;
      }
    }
  }

  &__sub {
    display: flex;
  }

  &__subImage {
    flex: 0 0 172px;
    width: 172px;
    height: 185px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__subImageFailed {
    height: 237px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 42px;

    img {
      width: 144px;
      height: 154px;
    }
  }

  &__subText {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    align-items: flex-start;
    justify-content: center;
  }
}

.completeMainImg {
  width: 196px;
  height: 163px;
  margin: auto auto -40px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.registerFailedImg {
  width: 128px;
  height: 137px;
  margin: auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.registerImg {
  width: 196px;
  height: 163px;
  margin: auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
