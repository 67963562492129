@charset "UTF-8";
@import '../../../../asset/css/partial/variables';

.label {
  display: flex;
  align-items: center;
  height: 18px;
  font-size: 14px;
  font-weight: bold;
  line-height: 100%;
  color: $main_text_color;
}

.inputWrap {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  .input {
    flex: 0 0 156px;
    @include mediaForPC() {
      flex: 0 0 162px;
    }
  }

  .inputCenter {
    margin: 8px 0;
    flex: 0 0 32px;
    font-size: 16px;
    font-weight: bold;
    line-height: 48px;
    text-align: center;

    @include mediaForPC() {
      flex: 0 0 42px;
    }
  }
}

.annotation {
  font-size: 12px;
  line-height: 12px;
  color: $sub_text_color;
}
