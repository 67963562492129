@charset "UTF-8";
@import '../../../../../asset/css/partial/variables';

.wrap {
  width: 100%;
}

.label {
  display: flex;
  align-items: center;
  height: 18px;
  font-size: 14px;
  font-weight: bold;
  line-height: 100%;
  color: $main_text_color;

  &.require {
    &::after {
      display: inline-block;
      height: 16px;
      padding: 0 7px;
      margin-left: 8px;
      font-size: 10px;
      line-height: 16px;
      color: #ff6363;
      letter-spacing: 0.01em;
      content: '必須';
      border: 1px solid #ff6363;
    }
  }
}

.input {
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  padding: 16px;
  margin: 8px 0;
  font-size: 16px;
  line-height: 16px;
  color: $main_text_color;
  background: #fff;
  border: 1px solid $input_border_color;
  border-radius: 3px;
  box-shadow: inset -1px 1px 2px rgba(0, 0, 0, 0.25);
  transition: all 0.25s ease-in-out;

  &::placeholder {
    color: $placeholder_text_color;
  }

  &:focus {
    border-color: $secondly_color;
  }

  &.error {
    border-color: #ff6363;
  }
}

.annotation {
  margin-bottom: 8px;
  font-size: 12px;
  color: $sub_text_color;
  text-align: left;
  white-space: pre-line;
}

.errorText {
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 100%;
  color: #f00;
  text-align: left;
}
