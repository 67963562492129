@charset "UTF-8";
@import "../../../../asset/css/partial/variables";

.schoolDetailInfo {
  line-height: 23.8px;

  table {
    width: 100%;
    font-size: 14px;
    table-layout: fixed;
    border-collapse: collapse;
  }

  tr {
    border-bottom: 1px solid $input_border_color;
    &:first-of-type {
      border-top: 1px solid $input_border_color;
    }
  }

  th {
    width: 86px;
    height: auto;
    padding: 19px 0 13px 8px;
    line-height: 23.8px;
    text-align: left;
    vertical-align: top;
    background: $sub_bg_color;

    @include mediaForPC() {
      width: 104px;
      padding: 17px 0 16px 16px;
    }
  }

  td {
    padding: 16px 8px;
    color: $main_text_color;

    @include mediaForPC() {
      padding: 16px 18px;
    }

    a {
      color: $main_text_color;
    }
  }

  .transportation {
    line-height: 23.8px;
    white-space: pre-wrap;
  }

  .openHours {
    .title {
      margin-bottom: 4px;
      font-weight: bold;
      letter-spacing: 0.25px;
    }

    .caution {
      margin: 0;
      color: #f00;
      letter-spacing: 0.25px;
    }

    & + .openHours {
      margin-top: 20px;
    }

    //開校時間
    .schoolDetailInfoSchedule {
      width: 239px;
      table-layout: fixed;
      border-right: 1px solid $input_border_color;
      border-left: 1px solid $input_border_color;

      th {
        width: 35.41px;
        padding: 3.5px 12px;
        font-size: 10px;
        line-height: 1;
        text-align: center;
        background: $main_bg_color;

        &.holiday {
          color: #c4c4c4;
        }
      }

      td{
        padding: 3.5px 3px 3.5px 6px;
        font-size: 10px;
        line-height: 14px;
        color: $main_text_color;
        text-align: left;

        @include mediaForPC() {
          text-indent: 4px;
        }


        &.holiday {
          color: #c4c4c4;
          text-align: center;
          text-indent: 0;
        }
      }

      .saturday {
        color: #1760a0;
      }
    
      .sunday {
        color: #f00;
      }

      @include mediaForPC() {
        width: 343px;

        th {
          width: 53.14px;
          padding: 8px 21.45px 5.66px 21.69px;
        }

        td{
          padding: 4.47px 11px 7.8px 8.66px;
        }
      }
    }
    .spaceForPc {
      display: none;
    }
    .finishTime {
      display: block;
    }
    @include mediaForPC() {
      .spaceForPc {
        display: inline;
      }
      .finishTime {
        display: inline;
      }
    }
  }

  .sns {
    span {
      display: block;
      font-weight: bold;
    }

    & + .sns {
      margin-top: 20px;
    }
  }

  &__place {
    @include mediaForPC() {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
