@charset "UTF-8";
@import "../../../../asset/css/partial/variables";

.paymentHistoryTable {
    th {
      background: $sub_bg_color;
    }
  
    th,td {
      border-left : 1px solid $main_bg_color;
      padding: 16px 8px;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: 0.1px;
    }
  
    .brown {
      background: $sub_bg_color;
    }
  
    &__id {
      width: 97px;
  
      a{
        text-decoration-line: underline;
        color: #17A076;
      }
    }
  
    &__name {
      display: block;
      width: 372px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  
    &__content {
      width: 254px;
    }
  
    &__inner {
      display: flex;
      justify-content: space-between;
      
      span {
        font-size: 10px;
      }
    }
  
    .note {
      margin-top: 4px;
      font-size: 10px;
      line-height: 140%;
      letter-spacing: 0.015em;
      color: #888888;
    }
  
    &__receipt{
      padding: 8px 16px;
      font-size: 14px;
      line-height: 100%;
      letter-spacing: 1.25px;
      color: $main_text_color;
      background: $main_bg_color;
      border: 1px solid $input_border_color;
      box-sizing: border-box;
      box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }
  }