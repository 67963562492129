@charset "UTF-8";
@import '../../../../asset/css/partial/variables';

.wrap {
  width: 605px;
}

.label {
  display: flex;
  align-items: center;
  height: 18px;
  font-size: 14px;
  font-weight: bold;
  line-height: 100%;
  color: $main_text_color;
}

.selected {
  margin-top: 8px;
  height: 77px;
  border: 1px dashed $input_border_color;
  box-sizing: border-box;
  background: $main_bg_color;

  .noSelected {
    height: 100%;
    font-weight: bold;
    font-size: 18px;
    line-height: 77px;
    text-align: left;
    letter-spacing: 0.15px;
    color: $input_border_color;
    margin-left: 40px;
  }

  .selectedList {
    padding: 8px 16px;

    li {
      font-size: 14px;
      margin-bottom: 8px;
    }
  }
}

.pickerWrap {
  width: 605px;
  height: 191px;
  margin-top: 8px;
  padding: 8px 16px 0;
  border: 1px solid $input_border_color;
  background: $main_bg_color;
}

.textInput {
  position: relative;

  img {
    top: 8px;
    position: absolute;
  }

  input {
    width: 100%;
    font-size: 12px;
    line-height: 140%;
    height: 32px;
    padding-left: 24px;
    letter-spacing: 0.4px;
    border-bottom: 1px solid $input_border_color;

    &::placeholder {
      color: $input_border_color;
    }
    &:-ms-input-placeholder {
      color: $input_border_color;
    }
    &::-ms-input-placeholder {
      color: $input_border_color;
    }
  }
}

.picker {
  height: 149px;
  padding: 16px 0 0;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;


  li {
    height: 30px;
    margin: 0 16px 8px 0;
    border: 1px solid $input_border_color;
    box-sizing: border-box;
    border-radius: 50px;

    button {
      height: 100%;
      padding: 8px;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 1.25px;
      color: $main_text_color;
      border-radius: 50px;
    }
  }
}

.annotation {
  font-size: 12px;
  line-height: 12px;
  color: $sub_text_color;
  margin-top: 8px;
}

.onFootWrap {
  display: flex;
  margin-top: 16px;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  line-height: 16px;
  color: #000000;

  &__input {
    padding: 16px;
    margin: 0 8px;
    width: 80px;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #DCDCDC;
    box-sizing: border-box;
    box-shadow: inset -1px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 3px;

    &:disabled {
      opacity: 0.3;
      &:hover {
        cursor: auto;
      }
    }
  }
}
