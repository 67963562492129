
@charset "UTF-8";
@import "../../../../asset/css/partial/variables";

.pageNation {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  margin-top: 24px;
  font-size: 14px;
  line-height: 48px;
  color: $main_text_color;
  text-align: center;
  background: $sub_bg_color;

  .pageNation__prev,
  .pageNation__next {
    position: relative;
    width: 45%;
    height: 100%;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
  }

  &__prev {
    &::before {
      position: absolute;
      top: calc(50% - 4px);
      left: 16px;
      display: inline-block;
      width: 7px;
      height: 7px;
      content: '';
      border-top: 2px solid $secondly_color;
      border-right: 2px solid $secondly_color;
      transform: rotate(225deg);
    }

    &.deactivate {
      pointer-events: none;

      &:hover {
        cursor: auto;
      }
    }

    &.deactivate::before,
    &.deactivate::after {
      border-top: 2px solid $input_border_color;
      border-right: 2px solid $input_border_color;
    }
  }

  &__next {
    &::after {
      position: absolute;
      top: calc(50% - 4px);
      right: 16px;
      display: inline-block;
      width: 7px;
      height: 7px;
      content: '';
      border-top: 2px solid $secondly_color;
      border-right: 2px solid $secondly_color;
      transform: rotate(45deg);
    }

    &.deactivate {
      pointer-events: none;

      &:hover {
        cursor: auto;
      }
    }

    &.deactivate::before,
    &.deactivate::after {
      border-top: 2px solid $input_border_color;
      border-right: 2px solid $input_border_color;
    }
  }
}