@charset "UTF-8";
@import "../../../asset/css/partial/variables";

.contractMore {
  display: block;
  padding: 16px;
  width: 368px;
  margin: 16px auto 0;
  background: $main_bg_color;
  border: 1px solid $input_border_color;
  box-sizing: border-box;
  box-shadow: 0px 4px 2px rgba(145, 137, 137, 0.2);
  border-radius: 24px;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 1.25px;
  color: $main_text_color;
  text-align: center;
}

.contractInfo{
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.1px;
  margin-left: 16px;

  a {
    text-decoration-line: underline;
    color: $secondly_color;
  }
}

.contractCreditCard {
  margin-top: 50px;
  
  .disable {
    display: none;
  }
}