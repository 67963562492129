@charset "UTF-8";
@import "../../../../asset/css/partial/variables";

.wrap {
  position: relative;
}

.schoolCoupon {
  display: block;
  padding: 16px;
  margin: 16px 16px 0;
  background: #FFF;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  &__head{
    display:flex;
    align-items: flex-start;
  }

  &__status{
    flex-shrink: 0;
    height: 30px;
    padding: 8px;
    font-size: 14px;
    line-height: 100%;
    color: #fff;
    letter-spacing: 1.25px;
    background: #FFCA67;

    &--hidden {
      color:#000;
      background: #C4C4C4;
    }
  }

  &__title {
    flex-grow: 1;
    margin-left: 8px;
    font-size: 16px;
    font-weight: bold;
    line-height: 140%;
    color: $main_text_color;
    letter-spacing: 0.15px;
  }

  &__info{
    margin-top: 8px;
    font-size: 12px;
    line-height: 140%;
    color: $main_text_color;
    letter-spacing: 0.4px;;
  }
}
