@charset "UTF-8";
@import "../../../../asset/css/partial/variables";

.schoolDetailTopOverview {
  line-height: 1.57;
  color: $main_text_color;

  .border {
    width: 100%;
    height: 1px;
    margin-bottom: 16px;
    border-bottom: 1px solid $input_border_color;
  }

  &__main {
    flex: 0 0 auto;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__thumbnails {
    display: flex;
    margin-right: -16px;
    overflow-x: scroll;
    overflow-y: hidden;
    
    @include mediaForPC() {
      margin-top: 8px;
      margin-right: 0;
      overflow-x: initial;
    }
  }

  &__thumbnail {
    flex: 0 0 auto;
    width: 64px;
    height: 64px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    & + .schoolDetailTopOverview__thumbnail {
      margin-left: 1px;
    }

    &.active {
      opacity: 0.3;
    }
  }
  
  @include mediaForPC() {
    &__main {
      width: 652px;
      height: 391px;
    }

    &__thumbnail {
      width: 67px;
      height: 66px;
  
      & + .schoolDetailTopOverview__thumbnail {
        margin-left: 6px;
      }
    }
  }

  &__title {
    margin-top: 24px;
    font-size: 18px;
    font-weight: bold;
  }

  &__text {
    margin-top: 24px;
    line-height: 30px;
    white-space: pre-wrap;
  }
}
