@charset "UTF-8";
@import "../../../../../../asset/css/partial/variables";

.inner {
  height: 310px;
  padding: 40px 96px;
  background: $sub_bg_color;
}

.greenBtn {
  display: block;
  line-height: 48px;
  width: 320px;
  height: 64px;
  margin: 40px auto auto;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background: $secondly_color;
  border-radius: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease-in-out;
}

.cancel {
  display: block;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 1.25px;
  color: $secondly_color;
  margin: 24px auto 0;
}

.confirmation {
  border-top: 1px solid $input_border_color;
  border-collapse: collapse;

  tr {
    border-bottom: 1px solid $input_border_color;
  }

  th {
    border-right: 1px solid $input_border_color;
    background: $sub_bg_color;
    padding: 16px;
    width: 178px;
    font-weight: bold;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.15px;
  }

  td {
    padding: 16px;
    width: 474px;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.15px;
  }

  .tdInner {
    display: flex;
    justify-content: space-between;

    &__right {
      text-align: right;
    }

    & + .tdInner {
      margin-top: 6px;
    }
  }

  .inquiryNum{
    font-size: 12px;
  }

  .inquiryLimit{
    display: block;
    font-size: 12px;
    color: $sub_text_color;
  }
}

.error {
  margin: 24px auto 8px;
  font-size: 16px;
  color: #f00;
  text-align: center;
}
