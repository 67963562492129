@charset "UTF-8";
@import "../../../../asset/css/partial/variables";

.ownerApplication{
  width:960px;
  padding: 8px 0;
  margin: auto;
  font-size: 14px;
  line-height: 140%;
  color: #000;
  text-align: right;
  letter-spacing: 0.1px;
  
  a {
    color: $secondly_color;
    text-decoration: underline;
  }
}