@charset "UTF-8";
@import '../../../../../asset/css/partial/variables';

.label {
  display: flex;
  align-items: center;
  height: 18px;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: bold;
  line-height: 100%;
  color: $main_text_color;

  &.require {
    &::after {
      display: inline-block;
      height: 16px;
      padding: 0 7px;
      margin-left: 8px;
      font-size: 10px;
      line-height: 16px;
      color: #ff6363;
      letter-spacing: 0.01em;
      content: '必須';
      border: 1px solid #ff6363;
    }
  }
}

.wrap {
  position: relative;
  display: inline-block;
  width: 100%;
  transition: opacity 0.25s ease-in-out;
  &.disable {
    pointer-events: none;
    cursor: auto;
    opacity: 0.3;
  }
}

.select {
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  padding: 8px 48px 8px 16px;
  font-size: 16px;
  line-height: 100%;
  background: #fff;
  border: 1px solid $input_border_color;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  &.noValue {
    color: $placeholder_text_color;
  }

  &:focus {
    color: $main_text_color;
  }

  &.hide {
    display: none;
  }

  option:disabled {
    color: $placeholder_text_color;
  }
}

.arrow {
  position: absolute;
  top: 0;
  right: 16px;
  bottom: 0;
  width: 16px;
  height: 16px;
  margin: auto;
  pointer-events: none;

  img {
    width: 100%;
    height: auto;
  }
}
