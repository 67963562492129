@charset "UTF-8";
@import "../../../../asset/css/partial/variables";

.row {
  display: flex;
  width: 100%;
  min-height: 52px;
  font-size: 14px;
  line-height: 140%;
  color: $main_text_color;
  letter-spacing: 0.1px;

  &.evenBg {
    background: $sub_bg_color;
  }

  > div {
    display: flex;
    align-items: flex-start;
    border-left: 1px solid #fff;

    > span {
      font-size: 10px;
    }

    &.noBorder {
      border: none;
    }
  }
}

.listTitleUser {
  display: flex;
  padding: 16px;
  flex: 375px;
  align-items: flex-start;
  justify-content: space-between;

  > div {
    flex: 1;
  }

  button {
    flex: 0 0 57px;
    padding: 0 16px;
    font-size: 12px;
    line-height: 140%;
    color: $secondly_color;
    text-decoration-line: underline;
    letter-spacing: 0.4px;
  }
}

.listTitleLastLogin {
  flex: 107px;
  padding: 16px;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.4px;
}

.listTitleRole {
  flex: 106px;
  padding: 10px 16px;

  .selectBoxWrap {
    position: relative;
  }

  .selectBox {
    box-sizing: border-box;
    padding: 10px 28px 10px 8px;
    font-size: 12px;
    font-weight: bold;
    line-height: 100%;
    color: $main_text_color;
    letter-spacing: 0.25px;
    background: $main_bg_color;
    background-image: url("../img/icon_arrow.svg");
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 13px 13px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
}

.listTitleAddDay {
  flex: 101px;
  padding: 16px;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.4px;
}

.listTitleMail {
  display: flex;
  flex: 129px;
  padding: 11px 16px;
  align-items: center;
  justify-content: space-between;

  input[type='radio'] {
    display: none;

    &:checked + label {
      color: $secondly_color;
      border: 1px solid $secondly_color;
    }

  }

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 44px;
    height: 30px;
    line-height: 100%;
    font-size: 14px;
    color: #C4C4C4;
    letter-spacing: 1.25px;
    border: 1px solid #C4C4C4;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

    &:hover {
      cursor: pointer;
    }
  }
}

.listTitleAction {
  flex: 110px;
  padding: 10px 16px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 78px;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid $placeholder_text_color;
    box-sizing: border-box;
    box-shadow: 0 4px 2px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 1.25px;
    color: $main_text_color;
  }
}