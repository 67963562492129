@charset "UTF-8";
@import '../../../../asset/css/partial/variables';

.wrap {
  width: 605px;
}

.label {
  display: flex;
  align-items: center;
  height: 18px;
  font-size: 14px;
  font-weight: bold;
  line-height: 100%;
  color: $main_text_color;


  button {
    display: flex;
    align-items: center;
    margin-left: 16px;
    font-size: 12px;
    line-height: 140%;
    color: $link_text_color_client;
    text-decoration-line: underline;
    letter-spacing: 0.4px;

    img {
      display: block;
      margin-right: 4px;
    }
  }
}

.selected {
  box-sizing: border-box;
  height: 77px;
  margin-top: 8px;
  background: $main_bg_color;
  border: 1px dashed $input_border_color;

  .noSelected {
    height: 100%;
    margin-left: 40px;
    font-size: 18px;
    font-weight: bold;
    line-height: 77px;
    color: $input_border_color;
    text-align: left;
    letter-spacing: 0.15px;
  }

  .selectedList {
    padding: 8px 16px;

    li {
      margin-bottom: 8px;
      font-size: 14px;
    }
  }
}

.pickerWrap {
  width: 605px;
  height: 191px;
  padding: 8px 16px 0;
  margin-top: 8px;
  background: $main_bg_color;
  border: 1px solid $input_border_color;
}

.textInput {
  position: relative;

  img {
    position: absolute;
    top: 8px;
  }

  input {
    width: 100%;
    height: 32px;
    padding-left: 24px;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: 0.4px;
    border-bottom: 1px solid $input_border_color;

    &::placeholder {
      color: $input_border_color;
    }
    &:-ms-input-placeholder {
      color: $input_border_color;
    }
    &::-ms-input-placeholder {
      color: $input_border_color;
    }
  }
}

.picker {
  display: flex;
  flex-wrap: wrap;
  height: 149px;
  padding: 16px 0 0;
  overflow-y: scroll;


  li {
    box-sizing: border-box;
    height: 30px;
    margin: 0 16px 8px 0;
    border: 1px solid $input_border_color;
    border-radius: 50px;

    button {
      height: 100%;
      padding: 8px;
      font-size: 14px;
      line-height: 14px;
      color: $main_text_color;
      letter-spacing: 1.25px;
      border-radius: 50px;
    }
  }
}

.annotation {
  margin-top: 8px;
  font-size: 12px;
  line-height: 12px;
  color: $sub_text_color;
}