@charset "UTF-8";
@import "../../../../../../asset/css/partial/variables";

.inner {
  height: 310px;
  padding: 40px 96px;
  background: $sub_bg_color;
}

.greenBtn {
  display: block;
  line-height: 48px;
  width: 320px;
  height: 64px;
  margin: 40px auto auto;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background: $secondly_color;
  border-radius: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease-in-out;

  &--notAdmin {
    margin-top:40px;
  }
}

.cancel {
  display: block;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 1.25px;
  color: $secondly_color;
  margin: 24px auto 0;
}

.label {
  display: flex;
  align-items: center;
  height: 18px;
  font-size: 14px;
  font-weight: bold;
  line-height: 100%;
  color: $main_text_color;

  &.require {
    &::after {
      display: inline-block;
      height: 16px;
      padding: 0 7px;
      margin-left: 8px;
      font-size: 10px;
      line-height: 16px;
      color: #ff6363;
      letter-spacing: 0.01em;
      content: '必須';
      border: 1px solid #ff6363;
      background: $main_bg_color;
    }
  }
}

.inputWrap {
  display: flex;
  align-items: center;
}
.upperLimit{
  display: flex;
  transition: opacity 0.3s ease-in-out;

  &.disable {
    opacity: 0.3;
    pointer-events: none;
  }

  &__explanation {
    background: $main_bg_color;
    margin-left: 24px;
    padding: 8px 16px;
    width: 170px;

    &Title{
      font-size: 10px;
      line-height: 140%;
      letter-spacing: 0.015em;
      color: $main_text_color;
      border-bottom: 1px solid $input_border_color;
      padding-bottom: 4px;
    }

    &Text{
      margin-top: 8px;
      font-size: 16px;
      line-height: 140%;
      letter-spacing: 0.15px;
      color: #545454;
    }

    &Note {
      margin-top: 8px;
      font-size: 10px;
      line-height: 140%;
      letter-spacing: 0.015em;
      color: $sub_text_color;    
    }
  }
}

.input {
  box-sizing: border-box;
  width: 73px;
  height: 48px;
  padding: 16px;
  margin: 8px;
  font-size: 16px;
  line-height: 16px;
  color: $main_text_color;
  background: #fff;
  border: 1px solid $input_border_color;
  border-radius: 3px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.25s ease-in-out;

  &::placeholder {
    color: $placeholder_text_color;
  }

  &:focus {
    border-color: $secondly_color;
  }

  &.error {
    border-color: #ff6363;
  }
}

.countBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: $main_bg_color;
  border: 1px solid $secondly_color;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  color: $secondly_color;
  font-size: 12px;
  font-weight: bold;
  
  &.disable {
    pointer-events: none;
  }
}

.note {
  font-size: 12px;
  line-height: 100%;
  color: $sub_text_color;
  margin-top: 8px;
}

//支払い情報登録なし
.title{
  font-weight: bold;
  font-size: 24px;
  line-height: 160%;
  text-align: center;
}

.borderWrap{
  margin-top:24px;
  background: $main_bg_color;
  border: 1px solid $input_border_color;
  box-sizing: border-box;
  padding: 40px;
}

.text {
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.15px;
}