@charset "UTF-8";
@import "../../../../asset/css/partial/variables";

.label {
  display: flex;
  align-items: center;
  height: 18px;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: bold;
  line-height: 100%;
  color: $main_text_color;

  &::after {
    display: inline-block;
    height: 16px;
    padding: 0 7px;
    margin-left: 8px;
    font-size: 10px;
    line-height: 16px;
    color: #ff6363;
    letter-spacing: 0.01em;
    content: '必須';
    border: 1px solid #ff6363;
  }
}

.innerWrap {
  width: 402px;
  padding: 16px 16px 0;
  border: 1px solid $input_border_color;
  box-sizing: border-box;
}

.innerLabel {
  height: 14px;
  font-size: 14px;
  font-weight: bold;
  line-height: 100%;
  color: $main_text_color;
}

.radioInputWrap {
  display: flex;
  align-items: center;
}

.radioWrap {
  margin-right: 24px;
}

.radioInput {
  display: none;

  &:checked + .radioLabel {
    color: $secondly_color;

    &::before {
      border-color: $secondly_color;
    }

    &::after {
      opacity: 1;
    }
  }
}

.radioLabel {
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 0 12px 24px;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  transition: all 0.25s ease-in-out;

  &::before {
    position: absolute;
    top: 14px;
    left: 0;
    box-sizing: border-box;
    display: block;
    width: 16px;
    height: 16px;
    margin: auto;
    content: '';
    background: #fff;
    border: 1px solid $input_border_color;
    border-radius: 52px;
    box-shadow: inset -1px -1px 2px rgba(0, 0, 0, 0.2);
    transition: all 0.25s ease-in-out;
  }

  &::after {
    position: absolute;
    top: 18px;
    left: 4px;
    box-sizing: border-box;
    display: block;
    width: 8px;
    height: 8px;
    margin: auto;
    content: '';
    background: $secondly_color;
    border-radius: 8px;
    opacity: 0;
    transition: all 0.25s ease-in-out;
  }
}

.timeWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  &__time {
    margin-bottom: 16px;
    flex: 0 0 156px;
    @include mediaForPC() {
      flex: 0 0 162px;
    }
  }

  &__timeCenter {
    flex: 0 0 32px;
    font-size: 16px;
    font-weight: bold;
    line-height: 48px;
    text-align: center;

    @include mediaForPC() {
      flex: 0 0 42px;
    }
  }
}

