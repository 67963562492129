@charset "UTF-8";
@import '../../../../../asset/css/partial/variables';

.label {
  display: flex;
  align-items: center;
  height: 18px;
  font-size: 14px;
  font-weight: bold;
  line-height: 100%;
  color: $main_text_color;

  &.require {
    &::after {
      display: inline-block;
      height: 16px;
      padding: 0 7px;
      margin-left: 8px;
      font-size: 10px;
      line-height: 16px;
      color: #ff6363;
      letter-spacing: 0.01em;
      content: '必須';
      border: 1px solid #ff6363;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    margin-left: 16px;
    font-size: 12px;
    line-height: 140%;
    color: $link_text_color_client;
    text-decoration-line: underline;
    letter-spacing: 0.4px;

    img {
      display: block;
      margin-right: 4px;
    }
  }
}

.wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.row {
  display: block;
}

.input {
  display: none;
}

.inputLabel {
  position: relative;
  display: block;
  height: 42px;
  padding-left: 24px;
  margin: 0 24px 0 0;
  font-size: 16px;
  line-height: 42px;
  color: $main_text_color;
  text-align: right;

  &:hover {
    cursor: pointer;
  }

  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    display: block;
    width: 16px;
    height: 16px;
    margin: auto;
    content: '';
    background: #fff;
    border: 1px solid #dcdcdc;
    border-radius: 2px;
    box-shadow: inset -1px -1px 2px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease-in-out;
  }

  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 1px;
    display: block;
    width: 14px;
    height: 14px;
    margin: auto;
    content: '';
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQuNzc0MjkgMTEuNTVDNC41NTAyOSAxMS41NSA0LjMyNjI5IDExLjQ1MiA0LjE3MjI5IDExLjI5OEwwLjgxMjI4OCA3Ljg0QzAuNDkwMjg4IDcuNTA0IDAuNDkwMjg4IDYuOTcyMDEgMC44MjYyODggNi42NTAwMUMxLjE2MjI5IDYuMzI4MDEgMS42OTQyOSA2LjMyODAxIDIuMDE2MjkgNi42NjRMNC43ODgyOSA5LjUyMDAxTDExLjU5MjMgMi42MTgwMUMxMS45MTQzIDIuMjgyMDEgMTIuNDQ2MyAyLjI4MjAxIDEyLjc4MjMgMi42MDQwMUMxMy4xMTgzIDIuOTI2MDEgMTMuMTE4MyAzLjQ1ODAxIDEyLjc5NjMgMy43OTQwMUw1LjM3NjI5IDExLjMxMkM1LjIyMjI5IDExLjQ2NiA0Ljk5ODI5IDExLjU1IDQuNzc0MjkgMTEuNTVaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K');
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }

  &--button {
    display: block;
    height: 48px;
    padding: 0 16px;
    margin: 8px 16px 8px 0;
    font-size: 16px;
    line-height: 46px;
    color: $main_text_color;
    letter-spacing: 0.5px;
    background: $main_bg_color;
    border: 1px solid #dcdcdc;
    box-shadow: inset -1px -1px 2px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease-in-out;

    &:hover {
      cursor: pointer;
    }
  }
}

.input:checked + .inputLabel {
  &::before {
    box-sizing: border-box;
    background: $secondly_color;
    border: 1px solid $secondly_color;
    box-shadow: inset -1px -1px 2px rgba(0, 0, 0, 0.2);
  }

  &::after {
    opacity: 1;
  }

  &--button {
    font-weight: bold;
    color: $secondly_color;
    border-color: $secondly_color;
    box-shadow: inset -1px -1px 2px rgba(23, 160, 118, 0.2);
  }
}

.annotation {
  font-size: 12px;
  line-height: 12px;
  color: $sub_text_color;
}
