@charset "UTF-8";
@import "../../../../../../asset/css/partial/variables";

.inner {
  height: 310px;
  padding: 40px 96px;
  background: $sub_bg_color;
}

.greenBtn {
  display: block;
  line-height: 48px;
  width: 320px;
  height: 64px;
  margin: 40px auto auto;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background: $secondly_color;
  border-radius: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease-in-out;
}

.title{
  font-weight: bold;
  font-size: 24px;
  line-height: 160%;
  text-align: center;
}

.borderWrap{
  margin-top:24px;
  background: $main_bg_color;
  border: 1px solid $input_border_color;
  box-sizing: border-box;
  padding: 40px;
}

.text {
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.15px;
}