@charset "UTF-8";
@import "../../../../asset/css/partial/variables";

.schoolEditBtnWrap {
  flex: 1 0 auto;
  text-align: right;
}
.schoolEditBtn {
  width: 179px;
  height: 31px;
  padding: 8px 16px;
  font-size: 12px;
  font-weight: bold;
  line-height: 129%;
  color: $main_bg_color;
  text-align: center;
  letter-spacing: 0.25px;
  background: $secondly_color;
  border-radius: 28px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
