.footer {
  margin-top: 96px;
  text-align: center;
  background: #F9F9F9;

  &__inner{
    max-width: 960px;
    padding: 24px 0;
    margin: auto;
  }

  ul {
    display: flex;
    justify-content: center;

    li {
      a{
        font-size: 12px;
        line-height: 140%;
        color: #1760A0;
        letter-spacing: 0.1px;
      }

      & + li {
        margin-left: 16px;
      }
    }
  }

  img {
    display: block;
    margin: auto;
    margin-top: 40px;
  }
  
  small {
    display: block;
    margin-top: 14px;
    font-size: 10px;
    font-size: 14px;
    line-height: 12px;
    line-height: 16px;
    color: #545454;
    color: #0768AB;
  }
}
