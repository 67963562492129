@charset "UTF-8";
@import "../../../../asset/css/partial/variables";

.showMoreBtn {
  box-sizing: border-box;
  display: block;
  width: 368px;
  height: 46px;
  padding: 16px;
  margin: 40px auto 0;
  font-size: 14px;
  line-height: 100%;
  color: $main_text_color;
  letter-spacing: 1.25px;
  background: $main_bg_color;
  border: 1px solid $input_border_color;
  border-radius: 24px;
  box-shadow: 0 4px 2px rgba(0, 0, 0, 0.2);
}
