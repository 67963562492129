@charset "UTF-8";
@import "../../../../../asset/css/partial/variables";

.wrap {
  height: 597px;
  padding: 89px 24px 24px;
  overflow-y: scroll;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 30px;
  color: #545454;
  letter-spacing: 0.5px;
  white-space: pre-wrap;
}

.buttonWrap {
  margin-bottom: 40px;
  text-align: center;
}
