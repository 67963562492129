@charset "UTF-8";
@import '../../../../asset/css/partial/variables';

.label {
  display: flex;
  align-items: center;
  height: 18px;
  font-size: 14px;
  font-weight: bold;
  line-height: 100%;
  color: $main_text_color;

  &.require {
    &::after {
      display: inline-block;
      height: 16px;
      padding: 0 7px;
      margin-left: 8px;
      font-size: 10px;
      line-height: 16px;
      color: #ff6363;
      letter-spacing: 0.01em;
      content: '必須';
      border: 1px solid #ff6363;
    }
  }
}

.selectWrap {
  margin: 8px auto 16px 0;
  width: 370px;

  &--end {
    width: 370px;
    margin: auto auto auto 0;
  }
}

.annotation {
  margin: 8px auto;
  font-size: 12px;
  color: $sub_text_color;
  text-align: left;
  white-space: pre-line;
}
