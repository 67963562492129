@charset "UTF-8";
@import "../../../../asset/css/partial/variables";

.wrap {
  position: absolute;
  top: 16px;
  right: 32px;
}

.menuBtn {
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
}

.forButtonBg {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  width: 100vw;
  height: 100vh;
  z-index: 998;
  opacity: 0;

  &.active {
    display: block;
  }
}

.list {
  position: absolute;
  z-index: 999;
  width: 184px;
  right: 0;
  top: 0;
  display: none;
  padding: 16px 0;
  background: #fff;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 7px 14px rgba(95, 97, 100, 0.15);

  &.active {
    display: block;
  }

  button {
    display: block;
    width: 184px;
    height: 46px;
    padding-left: 16px;
    font-style: normal;
    font-size: 14px;
    text-align: left;
    font-weight: 500;
    color: #545454;
    line-height: 100%;
    letter-spacing: 1.25px;
    background: #fff;
    transition: background 0.25s ease-in-out;

    &:hover {
      background: #E5E5E5;
    }
  }
}