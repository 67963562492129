@charset "UTF-8";
@import "./partial/variables";

// 共通
.schoolContainer {
  box-sizing: border-box;
  max-width: 960px;
  margin: auto;
  color: $main_text_color;

  h2 {
    padding-bottom: 16px;
    margin-top: 64px;
    font-size: 16px;
    font-weight: bold;
    line-height: 140%;
    color: $main_text_color;
    letter-spacing: 0.15px;
    border-bottom: 1px solid $input_border_color;
  }
}

.schoolInfoWrap {
  padding-top: 8px;
  margin: 0 16px 40px;
}

.schoolInfo {
  padding: 16px 0;
  border-bottom: 1px solid $main_border_color;

  &__title {
    font-size: 14px;
    font-weight: normal;
    line-height: 140%;
    color: $secondly_color;
    letter-spacing: 0.1px;
  }

  &__text {
    margin-top: 8px;
    line-height: 30px;
    color: $main_text_color;

    a {
      color: $main_text_color;
    }
  }

  &__sns + .schoolInfo__sns {
    margin-top: 20px;
  }
}

.schoolDetailInfoSchedule {
  width: 343px;
  border: 1px solid $input_border_color;


  th {
    width: 53.14px;
    height: 26px;
    padding: 8px 21.45px 5.66px 21.69px;
    font-size: 10px;
    line-height: 1;
    text-align: center;
    background: $main_bg_color;
    border-bottom: 1px solid $input_border_color;


    &.holiday {
      color: #c4c4c4;
    }
  }

  td {
    padding: 4.47px 11px 7.8px 8.66px;
    font-size: 10px;
    line-height: 14px;
    color: $main_text_color;
    text-align: left;
    text-indent: 4px;

    &.holiday {
      color: #c4c4c4;
      text-align: center;
      text-indent: 0;
    }
  }

  .saturday {
    color: #1760a0;
  }

  .sunday {
    color: #f00;
  }
}

.editInputButton {
  display: inline-block;
  width: 202px;
  height: 48px;
  padding: 0 16px;
  margin-top: 24px;
  font-size: 16px;
  font-weight: bold;
  line-height: 48%;
  color: $main_bg_color;
  letter-spacing: 1.25px;
  background: $secondly_color;
  border-radius: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color .25s ease-in-out;

  &--disable {
    pointer-events: none;
    background-color: $placeholder_text_color;
  }
}

.schoolDetailBackBtn {
  display: inline-block;
  margin: 40px 16px;
  font-size: 16px;
  color: $secondly_color;
  border-bottom: 1px solid $secondly_color;

  &--bottom{
    margin: 46px 0 0;
  }
}

.schoolDetailBtnWrap {
  button {
    display: block;
    height: 64px;
    margin: 24px auto 0;
  }
}

.schoolDetailImgWrapper{
  width: 310px;
  height: 192px;
  margin-top: 8px;

  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: 0% 100%;
  }
}

//スクール詳細TOP
.schoolDetailTopContentWrap {
  width: 652px;
  margin: 16px 0 48px 16px;
}

.schoolDetailTopCard {
  display: flex;
  max-width: 652px;
  padding: 24px;
  margin: 16px 16px 48px 16px;
  background: #F9F9F9;

  &__title {
    font-size: 16px;
    font-weight: bold;
    line-height: 140%;
    color: $main_text_color;
    letter-spacing: 0.15px;
  }

  &__text {
    margin-top: 8px;
    font-size: 14px;
    line-height: 140%;
    color: $main_text_color;
    letter-spacing: 0.1px;

    span {
      font-weight: bold;
    }
  }

  &__btn {
    box-sizing: border-box;
    width: 284px;
    height: 46px;
    margin-top: 16px;
    line-height: 46px;
    text-align: center;
    background: #FFF;
    border: 1px solid #DCDCDC;
    border-radius: 24px;
    box-shadow: 0 4px 2px rgba(0, 0, 0, 0.2);
    font-size: 14px;
    color: $main_text_color;
  }

  &__imgWrap {
    margin-left: 32px;
  }

  &__imgBg {
    padding: 8px;
    background: $main_bg_color;
  }

  &__img {
    width: 272px;
  }

  &__imgText {
    margin-top: 4px;
    font-size: 10px;
    line-height: 140%;
    color: #888;
    text-align: right;
    letter-spacing: 0.015em;
  }
}

// 編集UI
.schoolEditWrap {
  margin: 16px 0 48px 16px;
}

.editInputLabel {
  position: relative;
  display: inline-block;
  margin-top: 16px;
  font-size: 14px;
  line-height: 140%;
  color: $secondly_color;
  letter-spacing: 0.1px;

  &--require {
    @extend .editInputLabel;
    &::after {
      display: inline-block;
      position: relative;
      top: -1px;
      height: 16px;
      padding: 0 7px;
      margin-left: 8px;
      font-size: 10px;
      line-height: 16px;
      color: #ff6363;
      letter-spacing: 0.01em;
      font-weight: bold;
      content: '必須';
      border: 1px solid #ff6363;
    }
  }

  &--option {
    @extend .editInputLabel;
    &::after {
      content: '任意';
      display: inline-block;
      position: relative;
      top: -1px;
      height: 16px;
      padding: 0 7px;
      margin-left: 8px;
      font-size: 10px;
      line-height: 16px;
      color: $sub_text_color;
      letter-spacing: 0.01em;
      font-weight: bold;
      border: 1px solid $sub_text_color;
    }
  }
}

.editInputWrap {
  padding-bottom: 24px;
  border-bottom: 1px solid $placeholder_text_color;
}

.editImageListWrap {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 24px;
  border-bottom: 1px solid $placeholder_text_color;
}

.editInputQaWrap {
  padding-bottom: 16px;
}

.editInputQaAnswerWrap {
  margin-bottom: 40px;
}

//コース一覧
.schoolCourseListAddInstructor {
  box-sizing: border-box;
  display: inline-block;
  padding: 16px;
  margin-top: 24px;
  font-size: 16px;
  font-weight: bold;
  line-height: 100%;
  color: $secondly_color;
  letter-spacing: 1.25px;
  background: $main_bg_color;
  border: 1px solid $secondly_color;
  border-radius: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

//コース詳細
.schoolCourseDetailInstructorWrap {
  margin-top: 8px;
}

.schoolCourseDetailInstructor {
  & + .schoolCourseDetailInstructor {
    margin-top: 16px;
  }

  &__head{
    display: flex;
  }

  &__img{
    width: 64px;
    height: 64px;
    margin-right: 8px;
    border-radius: 50%;
  }

  &__info{
    font-size: 16px;
    line-height: 30px;
    color: $main_text_color;
    letter-spacing: 0.5px;
  }

  &__body{
    font-size: 16px;
    line-height: 30px;
    color: $main_text_color;
    letter-spacing: 0.5px;
  }
}

.schoolCourseDetailInstructorInputList {
  display: flex;
  flex-wrap: wrap;
  margin-right: -16px;
  padding-top: 8px;

  .schoolCourseDetailInstructorInputListLow {
    display: flex;
    align-items: center;
    width: 455px;
    height: 96px;
    padding: 16px 8px;
    margin: 0 16px 16px 0;
    background: #f9f9f9;

    &--inDetail {
      @extend .schoolCourseDetailInstructorInputListLow;
      width: 440px;
    }

    label {
      position: relative;
      display: block;
      height: 42px;
      padding-left: 16px;
      margin: 0 8px 0 0;
      font-size: 16px;
      line-height: 42px;
      color: $main_text_color;
      text-align: right;

      &:hover {
        cursor: pointer;
      }

      &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        box-sizing: border-box;
        display: block;
        width: 16px;
        height: 16px;
        margin: auto;
        content: '';
        background: #fff;
        border: 1px solid #dcdcdc;
        border-radius: 2px;
        box-shadow: inset -1px -1px 2px rgba(0, 0, 0, 0.1);
        transition: all 0.2s ease-in-out;
      }

      &::after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 1px;
        display: block;
        width: 14px;
        height: 14px;
        margin: auto;
        content: '';
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQuNzc0MjkgMTEuNTVDNC41NTAyOSAxMS41NSA0LjMyNjI5IDExLjQ1MiA0LjE3MjI5IDExLjI5OEwwLjgxMjI4OCA3Ljg0QzAuNDkwMjg4IDcuNTA0IDAuNDkwMjg4IDYuOTcyMDEgMC44MjYyODggNi42NTAwMUMxLjE2MjI5IDYuMzI4MDEgMS42OTQyOSA2LjMyODAxIDIuMDE2MjkgNi42NjRMNC43ODgyOSA5LjUyMDAxTDExLjU5MjMgMi42MTgwMUMxMS45MTQzIDIuMjgyMDEgMTIuNDQ2MyAyLjI4MjAxIDEyLjc4MjMgMi42MDQwMUMxMy4xMTgzIDIuOTI2MDEgMTMuMTE4MyAzLjQ1ODAxIDEyLjc5NjMgMy43OTQwMUw1LjM3NjI5IDExLjMxMkM1LjIyMjI5IDExLjQ2NiA0Ljk5ODI5IDExLjU1IDQuNzc0MjkgMTEuNTVaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K');
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0;
        transition: all 0.2s ease-in-out;
      }
    }

    input:checked + .inputLabel {
      &::before {
        box-sizing: border-box;
        background: $secondly_color;
        border: 1px solid $secondly_color;
        box-shadow: inset -1px -1px 2px rgba(0, 0, 0, 0.2);
      }

      &::after {
        opacity: 1;
      }

      &--button {
        color: $secondly_color;
        border-color: $secondly_color;
        box-shadow: rgba(0, 0, 0, 0);
      }
    }
  }

  .schoolCourseDetailInstructorInputListImage {
    flex: 0 0 65px;
    width: 65.13px;
    height: 64px;
    margin-right: 16px;
    overflow: hidden;
    border-radius: 130px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .schoolCourseDetailInstructorInputListText {
    flex: 1;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 30px;
    letter-spacing: 0.5px;
    min-width: 0;
    width:100%;
    min-height:100%;

    > div {
      min-width: 0;
      width:100%;
      min-height:100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

//ブログ詳細
.schoolBlogDetail{
  &ImgWrapper{
    width: 652px;
    height: 320px;
    img {
      width:100%;
      height: 100%;
      object-fit: contain;
      object-position: 0 100%;
    }
  }
  &Title {
    margin-top:16px;
    font-size: 24px;
    font-weight: bold;
    line-height: 160%;
    color: #545454;
  }
  &Text {
    margin-top:8px;
    font-size: 16px;
    line-height: 30px;
    color: #000;
    letter-spacing: 0.5px;
  }

  &Draft {
    margin: 16px 0 -30px;
  }
}

// サブコンテンツ新規UI
.buttonWrap {
  margin-bottom: 40px;
  text-align: center;
}

.linkText {
  color: $secondly_color;
}

.courseInstructorDescription {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 140%;
}

.description{
  margin-top: 8px;
  font-size: 12px;
  line-height: 100%;
  color: $sub_text_color;
}
