@charset "UTF-8";
@import "../../../../asset/css/partial/variables";

.title {
  font-size: 14px;
  line-height: 140%;
  color: #000;
  letter-spacing: 0.1px;
}

.btnWrap {
  display: flex;
}

.btn {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  margin-right: 16px;
  font-size: 12px;
  font-weight: bold;
  line-height: 100%;
  color: $main_text_color;
  letter-spacing: 0.25px;
  pointer-events: auto;
  background: $main_bg_color;
  border: 1px solid $input_border_color;
  border-radius: 44px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  opacity: 1;
  transition: opacity 0.3s ease-in-out;

  img {
    margin-right: 6px;
  }

  &.disable {
    pointer-events: none;
    opacity: 0.3;
  }
}

.selectBox {
  box-sizing: border-box;
  padding: 10px 38px 10px 18px;
  margin-right: 16px;
  font-size: 12px;
  font-weight: bold;
  line-height: 100%;
  color: $main_text_color;
  letter-spacing: 0.25px;
  pointer-events: auto;
  background: $main_bg_color;
  background-image: url("../img/icon_arrow.svg");
  background-repeat: no-repeat;
  background-position: right 12px center;
  border: 1px solid $input_border_color;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  opacity: 1;
  transition: opacity 0.3s ease-in-out;

  &.disable {
    pointer-events: none;
    opacity: 0.3;
  }
}

table {
  margin-top: 16px;
}

table , td, th {
  border-collapse: collapse;
}
td, th {
  height: 49px;
}
th {
  padding-left: 8px;
  font-size: 12px;
  text-align: left;
  background: $sub_bg_color;
}

//チェックボックス
.schoolListCheckBox{
  display:none;
}

.schoolListLabel{
  position: relative;
  display: block;
  padding-left: 24px;
  margin: 0 24px 0 0;
  font-size: 14px;
  color: $main_text_color;

  &:hover {
    cursor: pointer;
  }

  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    display: block;
    width: 16px;
    height: 16px;
    content: '';
    background: #fff;
    border: 1px solid $input_border_color;
    border-radius: 2px;
    box-shadow: inset -1px -1px 2px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease-in-out;
  }
  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 1px;
    display: block;
    width: 14px;
    height: 14px;
    content: '';
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQuNzc0MjkgMTEuNTVDNC41NTAyOSAxMS41NSA0LjMyNjI5IDExLjQ1MiA0LjE3MjI5IDExLjI5OEwwLjgxMjI4OCA3Ljg0QzAuNDkwMjg4IDcuNTA0IDAuNDkwMjg4IDYuOTcyMDEgMC44MjYyODggNi42NTAwMUMxLjE2MjI5IDYuMzI4MDEgMS42OTQyOSA2LjMyODAxIDIuMDE2MjkgNi42NjRMNC43ODgyOSA5LjUyMDAxTDExLjU5MjMgMi42MTgwMUMxMS45MTQzIDIuMjgyMDEgMTIuNDQ2MyAyLjI4MjAxIDEyLjc4MjMgMi42MDQwMUMxMy4xMTgzIDIuOTI2MDEgMTMuMTE4MyAzLjQ1ODAxIDEyLjc5NjMgMy43OTQwMUw1LjM3NjI5IDExLjMxMkM1LjIyMjI5IDExLjQ2NiA0Ljk5ODI5IDExLjU1IDQuNzc0MjkgMTEuNTVaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K');
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }
}


.schoolListCheckBox:checked + .schoolListLabel {
  &::before {
    box-sizing: border-box;
    background: $secondly_color;
    border: 1px solid $secondly_color;
    box-shadow: inset -1px -1px 2px rgba(0, 0, 0, 0.2);
  }

  &::after {
    opacity: 1;
  }
}
