@charset "UTF-8";
@import "../../../asset/css/partial/variables";


.flow {
  width: 812px;
  height: 274px;
  margin: 40px auto 0;
  padding: 24px;
  background: $sub_bg_color;

  &__title {
    margin-bottom: 24px;
  }

  &__img {
    width: 100%;
    display: block;
  }
}