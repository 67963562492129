@charset "UTF-8";
@import "../../../../asset/css/partial/variables";

.schoolInstructorCard {
  padding: 16px;
  margin:16px 16px 0;
  background: $sub_bg_color;

  &--edit{
    .schoolInstructorCard{
      &__info,&__body {
        font-size: 14px;
        line-height: 140%;
        color: $secondly_color;
        letter-spacing: 0.1px;
      }

      &__name{
        width: 468px;
      }

      &__time{
        display: flex;
        align-items: center;

        div{
          width: 80px;
          margin-left: 8px;
          + span{
            margin-left: 8px;
          }
        }
      }
    }
  }
  
  &__head{
    display: flex;
    margin-bottom: 16px;
  }

  &__img{
    width: 64px;
    height: 64px;
    margin-right: 8px;
    border-radius: 50%;
  }

  &__info{
    flex-grow: 1;
    font-size: 16px;
    line-height: 30px;
    color: $main_text_color;
    letter-spacing: 0.5px;
  }

  &__button {
    box-sizing: border-box;
    height: 28px;
    padding: 7px 16px;
    font-size: 12px;
    font-weight: bold;
    line-height: 120%;
    color: $secondly_color;
    text-align: center;
    letter-spacing: 0.25px;
    background: $main_bg_color;
    border: 1px solid $secondly_color;
    border-radius: 28px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  &__body{
    font-size: 16px;
    line-height: 30px;
    color: $main_text_color;
    letter-spacing: 0.5px;
  }

  &__buttons {
    display: flex;
  }

  &__saveBtn{
    top: 2747px;
    left: 192px;
    width: 202px;
    height: 48px;
    font-size: 16px;
    font-weight: bold;
    line-height: 100%;
    color: #FFF;
    letter-spacing: 1.25px;
    background: $secondly_color;
    border-radius: 24px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  &__deleteBtn {
    margin-left: 24px;
    font-size: 14px;
    line-height: 140%;
    color: $secondly_color;
    text-decoration-line: underline;
    letter-spacing: 0.1px;
  }
  
}
