@charset "UTF-8";
@import "../../../../asset/css/partial/variables";

.schoolDetailTopQa {
  color: $main_text_color;

  .qaCard {
    padding: 16px 18px 16px 7px;
    line-height: 140%;
    background: $main_bg_color;
    border: 1px solid $input_border_color;

    @include mediaForPC() {
      padding: 16px;
    }

    &__question {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      padding-left: 28px;
      line-height: 140%;
      color: $main_text_color;
      text-align: left;

      img {
        margin-left: 16px;
      }

      @include mediaForPC() {
        padding: 0 0 0 32px;
      }

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        font-weight: bold;
        color: $secondly_color;
        content: 'Q';
      }

      &.showAnswer {
        padding-bottom: 16px;

        img {
          transform: rotate(-180deg);
        }
      }
    }

    &__answer {
      position: relative;
      height: 0;
      padding: 0;
      visibility: hidden;
      border-top: 1px solid $input_border_color;
      transition: transform 0.3s;
      transform: translateY(-30px);

      &::before {
        position: absolute;
        top: 16px;
        left: 0;
        display: inline-block;
        font-weight: bold;
        color: $secondly_color;
        content: 'A';
      }

      &.showAnswer {
        height: 100%;
        padding: 16px 6px 0 28px;
        visibility: visible;
        transform: translateY(0);
      }
    }

    & + .qaCard {
      margin-top: 16px;
    }
  }
}
