@charset "UTF-8";
@import "../../../../../asset/css/partial/variables";

.wrap {
  height: 597px;
  padding: 89px 75px 0;
}

.inner {
  height: 310px;
  padding: 40px 96px;
  background: $sub_bg_color;
}

.radioWrap {
  margin-top: 10px;
}

.buttonWrap {
  margin-bottom: 40px;
  text-align: center;
}

.cancelButton {
  font-style: normal;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 1.25px;
  color: $secondly_color;
}

.title {
  margin-bottom: 24px;
  font-size: 14px;
  font-weight: bold;
  line-height: 100%;
  color: $main_text_color;
}

.text {
  margin-bottom: 32px;
  font-size: 16px;
  font-weight: normal;
  line-height: 100%;
  color: $main_text_color;
}

.description {
  font-size: 12px;
  line-height: 100%;
  color: $sub_text_color;
}

.resultTitle {
  font-weight: bold;
  font-size: 24px;
  line-height: 160%;
  text-align: center;
}

.confirmation{
  box-sizing: border-box;
  width: 652px;
  padding: 24px 32px 40px;
  margin: 40px auto 0;
  color: $main_text_color;
  background: #FFF;
  border: 1px solid $input_border_color;

  &__conversation {
    font-size: 24px;
    font-weight: bold;
    line-height: 160%;
    color: $secondly_color;
    text-align: center;
  }

  &__title {
    font-size: 12px;
    font-weight: bold;
    line-height: 140%;
    letter-spacing: 0.4px;
  }

  &__text{
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.15px;
  }

  span {
    color: $secondly_color;
  }

  &__list {
    margin-top: 8px;
    text-align: left;

    li {
      font-size: 12px;
      line-height: 140%;
      color: $main_text_color;
      letter-spacing: 0.4px;

      & + li {
        margin-top: 16px;
      }
    }
  }

  &__emphasisList {
    text-align: left;
    list-style: disc;

    li{
      font-size: 14px;
      font-weight: bold;
      line-height: 170%;
      color: $main_text_color;

      & + li {
        margin-top: 16px;
      }
    }
  }
}
