@charset "UTF-8";
@import "../../../../asset/css/partial/variables";

.breadCrumb{
  display: inline-block;
  margin-top: 16px;
  font-size: 10px;
  line-height: 100%;
  color: $sub_text_color;

  &--withArrow {
    position: relative;
    padding-right: 10px;
    margin-right: 4px;

    &::after {
      position: absolute;
      top:0;
      right: 0;
      display: inline-block;
      content:">";
    }
  }
}