@charset "UTF-8";
@import "../../../../asset/css/partial/variables";

.greenBtn {
  display: block;
  line-height: 64px;
  width: 320px;
  height: 64px;
  margin: 40px auto auto;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background: $secondly_color;
  border-radius: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease-in-out;
}
