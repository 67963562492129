@charset "UTF-8";
@import "../../../../asset/css/partial/variables";

.schoolH3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  font-size: 16px;
  font-weight: bold;
  line-height: 140%;
  color: $main_text_color;
  letter-spacing: 0.15px;
  border-bottom: 1px solid $input_border_color;

  &__inner{
    display: flex;
    align-items: center;
  }

  &__status{
    flex-shrink: 0;
    height: 22px;
    padding: 0 8px;
    margin-left: 16px;
    font-size: 12px;
    font-weight: normal;
    line-height: 22px;
    color: #fff;
    letter-spacing: 1.25px;
    background: #FFCA67;

    &--hidden {
      color: #000;
      background: #C4C4C4;
    }
  }
  
  button {
    box-sizing: border-box;
    padding: 8px 16px;
    font-size: 12px;
    font-weight: bold;
    line-height: 120%;
    color: $secondly_color;
    text-align: center;
    letter-spacing: 0.25px;
    background: $main_bg_color;
    border: 1px solid $secondly_color;
    border-radius: 28px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  &__note {
    font-size: 14px;
    font-weight: normal;
    line-height: 140%;
    color: $main_text_color;
    letter-spacing: 0.1px;
    
    a {
      color: $primary_color;
      text-decoration: underline;
    }
  }
}
