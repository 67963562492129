@charset "UTF-8";
@import "../../../../asset/css/partial/variables";

.inquiryCard {
  display: block;
  padding: 16px;
  margin: 16px 16px 0;
  background: $main_bg_color;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  &__head{
    display:flex;
    align-items: flex-start;
  }
  
  &__mainImg {
    width: 140px;
    height: 86px;
  }

  &__status{
    position: absolute;
    flex-shrink: 0;
    height: 18px;
    padding:4px;
    font-size: 10px;
    line-height: 100%;
    color: #fff;;
    letter-spacing: 1.25px;
    background: #FFCA67;
  }

  &__textWrap {
    flex-grow: 1;
    margin-left: 8px;
  }

  &__title {
    font-size: 16px;
    font-weight: bold;
    line-height: 140%;
    color: $main_text_color;
    letter-spacing: 0.15px;
  }

  &__place {
    margin-top: 8px;
    font-size: 12px;
    font-weight: normal;
    line-height: 140%;
    color: $main_text_color;
    letter-spacing: 0.4px;
  }

  &__body {
    margin-top: 16px;
  }

  &__infoWrap{
    display: flex;
    border-top: 1px solid $main_border_color;

    & + .mypageInquiryCard__infoWrap{
      border-bottom: 1px solid $main_border_color;
    }
  }

  &__infoTitle{
    width: 45px;
    padding: 8px;
    font-size: 14px;
    line-height: 140%;
    color: $main_text_color;
    letter-spacing: 0.1px;
    background: $sub_bg_color;

    &--long {
      width: 87px;
    }
  }

  &__infoText{
    width: 197px;
    padding: 8px;
    font-size: 14px;
    line-height: 140%;
    color: $main_text_color;
    letter-spacing: 0.1px;

    &--long {
      flex-grow: 1;
    }
  }
}
